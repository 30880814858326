import { CheckBox } from "components/CheckBox";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { SignUpContainer } from "hooks/useSignUp";
import { useState, useEffect } from "react";

/**
 * Row displayed when choosing membership.
 */

export default function MembershipChoiceItem({
  selected,
  img,
  price,
  amountDue,
  amountDueAfter,
  digital,
  onClick,
  title,
}) {
  const { date, dateStatus } = SignUpContainer.useContainer();
  const [_date, setDate] = useState("");
  useEffect(() => {
    if (date && date.trialEnd) {
      var dateFragments = date.trialEnd.substring(0, 10).split("-");
      var formatDate = `${dateFragments[1]}.${dateFragments[2]}.${dateFragments[0]}`;
      setDate(formatDate);
    }
  }, [date]);
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.select}>
          <CheckBox title={"Select"} selected={selected} onClick={onClick} />
        </div>
        <div className={styles.imgBox}>
          <img className={styles.img} src={img} alt={img} />
        </div>
        <div className={styles.prices}>
          <div className={styles.type}>{title}</div>
          <div className={styles.row2}>
            <div className={styles.subRow}>
              <div className={styles.gold}>Complimentary IBO</div>
              <div className={styles.gold}>
                Expires:{" "}
                {dateStatus === "success"
                  ? _date
                  : "Retrieving Trial End Date..."}
              </div>
            </div>
            <div className={styles.subRow}>
              <div>
                Next Billing:{" "}
                {dateStatus === "success"
                  ? _date
                  : "Retrieving Trial End Date..."}
              </div>
              <div>${price}/mo.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MembershipChoiceItem.propTypes = {
  selected: PropTypes.bool, // Boolean to show id checkbox is checked
  img: PropTypes.string, // String that displays the img
  price: PropTypes.string, // Displays the membership price
  amountDue: PropTypes.string, // Displays the price due today
  amountDueAfter: PropTypes.string, // Displays the price due after 30 days
  digital: PropTypes.bool, // If true displays that its digital delivery choice.
  onClick: PropTypes.func, // function you create to toggle the check boxs.
};
