import AddressCard from "components/AddressCard";
import styles from "./index.module.css";
import Arrow from "../../Images/Address_Arrow.svg";
import classNames from "classnames";
import { Button } from "components/Button";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "-2%",
    bottom: "auto",
  },
  overlay: { zIndex: 1000, borderRadius: "6pt" },
};

Modal.setAppElement('#root');

export default function AddressConfirmation({ visible, original, suggested, onOriginalSelected, onSuggestedSelected, onEdit, onCancel }) {
  return (
    <div
        className={classNames(styles.modalOverlay, {
          [styles.show]: visible,
        })}
      >
        <Modal
          isOpen={visible}
          onRequestClose={onCancel}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className={styles.container}>
            <div className={styles.addressContainer}>
              <h3>Please Confirm Your Address</h3>
              {suggested && (<AddressCard
                header={"Suggested Address"}
                className={styles.btnColor}
                arrow={Arrow}
                address1={suggested.line1 + `${suggested.line2 ? "\n" + suggested.line2 : ""}`}
                address2={`${suggested.city}, ${suggested.state} ${suggested.postalCode}`}
                address3={suggested.country}
                onClick={onSuggestedSelected}
              />)}

              <AddressCard
                header={"Original Address"}
                className={styles.btnColor2}
                backgroundColor={styles.backgroundColor}
                edit={true}
                address1={original.line1 + `${original.line2 ? "\n" + original.line2 : ""}`}
                address2={`${original.city}, ${original.state} ${original.postalCode}`}
                address3={original.country}
                onClick={onOriginalSelected}
                onEdit={onEdit}
              />
            </div>
            <div className={styles.btn}>
              <Button
                onClick={onCancel}
                title={"Cancel"}
                className={styles.btnColor3}
              />
            </div>
          </div>
        </Modal>
      </div>
  );
}