import styles from "./index.module.css";
import PropTypes from "prop-types";
import { SignUpContainer } from "hooks/useSignUp";

/**
 * Row in Receipt that displays item purchased
 */

export default function ReceiptItem({
  itemName,
  price,
  amount,
  proCreditPrice = null,
  containerClassName = styles.container,
  amountClassName = styles.amount
}) {
  const { currencyFormatter } = SignUpContainer.useContainer()

  return (
    <div className={containerClassName}>
      <div className={styles.row}>
        <div>
          {itemName}: {price > 0 ? currencyFormatter.format(price) : ""} {price > 0 ? "Monthly" : ""}
        </div>
        <div className={amountClassName}>{currencyFormatter.format(amount)}</div>
      </div>
      {proCreditPrice > 0 && (<div>Prorated Credit: {currencyFormatter.format(proCreditPrice)}</div>)}
    </div>
  );
}

ReceiptItem.propTypes = {
  itemName: PropTypes.string,
  price: PropTypes.number,
  amount: PropTypes.number,
  proCreditPrice: PropTypes.number,
  info: PropTypes.string,
};
