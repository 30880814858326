import { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import { FooterModal } from "components/FooterModal";
import { SignUpContainer } from "hooks/useSignUp";
import { ReactComponent as LockIcon } from "./private-lock-icon.svg";

/**
 * Step for user to add their payment method
 */

export default function Payment({
  cardZipCode,
  setZipCode,
  nickname,
  setNickname,
  visible,
  setVisible,
  iboNum,
  onBack,
  onClick,
  onPaymentSubmit,
}) {
  const { error, getTokenizationKey, setShowLoadingIcon } =
    SignUpContainer.useContainer();

  const ref = useRef();
  const buttonRef = useRef();

  const [showPci, setShowPci] = useState(false);

  useEffect(() => {
    setShowLoadingIcon(true);
    getTokenizationKey.mutateAsync().then(() => {
      setShowLoadingIcon(false);
    });

    return () => {
      window.CollectJS = null;
    };
  }, []);

  const handleClick = () => {
    onClick && onClick();
  };

  // weird workaround to make sure Glacier's validation callback is called
  const wait = (time) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });

  const handleSubmit = async (event) => {
    ref.current.click();
    event.preventDefault();
    await wait(500);
    buttonRef.current.click();
  };

  const buttonClick = () => {
    handlePaymentSubmit();
  };

  const handlePaymentSubmit = () => {
    onPaymentSubmit && onPaymentSubmit();
  };

  const pageError = (error && error.Payment) || {};

  const topError =
    pageError.default ||
    pageError.cardZipCode ||
    pageError.cardNickName ||
    pageError.ccnumber ||
    pageError.ccexp;
  return (
    <>
      <form>
        <div className={styles.container}>
          <div className={styles.header}>
            <PersonalInfoHeader
              iboNumber={iboNum}
              subHeader={"Payment Information"}
            />
          </div>

          <div
            className={classnames(styles.input, {
              [styles.error]: !!pageError.cardZipCode,
            })}
          >
            <InputBox
              id="ccZip"
              name="ccZip"
              placeHolder={"Billing Zip Code"}
              label={"Zip Code*:"}
              value={cardZipCode}
              setValue={setZipCode}
            />
          </div>

          <div
            className={classnames(styles.input, {
              [styles.error]: !!pageError.cardNickName,
            })}
          >
            <InputBox
              id="ccNickname"
              name="ccNickname"
              placeHolder={"Nickname for this card"}
              label={"Nickname*:"}
              value={nickname}
              setValue={setNickname}
            />
          </div>
          <div className={styles.cardTypes}>
            We currently accept Visa®, Mastercard®, Discover®, JCB or Diners
            Club International®.
          </div>

          <div className={styles.paymentInput}>
            <div className={styles.paymentInputIconContainer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33.863"
                height="24"
                viewBox="0 0 33.863 23"
              >
                <path
                  id="Credit_Card_Icon"
                  d="M3.125,21.077V38.252a2.914,2.914,0,0,0,2.912,2.912H34.076a2.914,2.914,0,0,0,2.912-2.912V21.077a2.914,2.914,0,0,0-2.912-2.912c-5.551,0-22.488,0-28.038,0a2.912,2.912,0,0,0-2.912,2.911ZM33.6,31.005a1.128,1.128,0,0,0-1.129-1.129H26.264A1.128,1.128,0,0,0,25.136,31v5.081a1.129,1.129,0,0,0,1.129,1.129h6.208a1.129,1.129,0,0,0,1.129-1.13ZM8.2,37.214H9.9a1.129,1.129,0,1,0,0-2.258H8.2a1.129,1.129,0,1,0,0,2.258Zm5.644,0h7.9a1.129,1.129,0,1,0,0-2.258h-7.9a1.129,1.129,0,1,0,0,2.258ZM34.73,23.66H5.383V21.078a.655.655,0,0,1,.655-.655H34.076a.655.655,0,0,1,.655.655Z"
                  transform="translate(-3.125 -18.164)"
                  fill="#415364"
                  fillRule="evenodd"
                />
              </svg>
              <div className={styles.ccAsterisk}>
                <svg
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.62451 0L3.35156 2.50732L5.87793 1.80273L6.04297 2.95801L3.62451 3.15479L5.19873 5.22412L4.10693 5.8208L2.98975 3.52295L1.97412 5.8208L0.856934 5.22412L2.39307 3.15479L0 2.95801L0.184082 1.80273L2.66602 2.50732L2.39307 0H3.62451Z"
                    fill="#415364"
                  />
                </svg>
              </div>
            </div>
            <div
              id="ccnumber"
              className={classnames(styles.paymentInputInput, {
                [styles.errorDiv]: !!pageError.ccnumber,
              })}
            ></div>
          </div>

          <div className={styles.paymentInput}>
            <div className={styles.paymentInputIconContainer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.955"
                height="24"
                viewBox="0 0 21.955 23"
              >
                <path
                  id="Calendar_Icon"
                  d="M13.758,6a.522.522,0,0,0-.523.523V9.4a.523.523,0,0,0,1.045,0V6.523A.522.522,0,0,0,13.758,6ZM24.212,6a.522.522,0,0,0-.523.523V9.4a.523.523,0,0,0,1.045,0V6.523A.522.522,0,0,0,24.212,6ZM10.1,8.091A2.1,2.1,0,0,0,8,10.19v4.7H29.954v-4.7a2.1,2.1,0,0,0-2.1-2.1h-2.6V9.945a2.091,2.091,0,1,1-2.091,0V8.091H14.8V9.937a2.09,2.09,0,1,1-2.091.008V8.091ZM13.758,10.7A1.045,1.045,0,1,0,14.8,11.75,1.038,1.038,0,0,0,13.758,10.7Zm10.455,0a1.045,1.045,0,1,0,1.045,1.045A1.038,1.038,0,0,0,24.212,10.7ZM8,15.932V26.9A2.1,2.1,0,0,0,10.1,29H27.855a2.1,2.1,0,0,0,2.1-2.1V15.932Z"
                  transform="translate(-8 -6)"
                  fill="#415364"
                />
              </svg>
              <div className={styles.ccAsterisk}>
                <svg
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.62451 0L3.35156 2.50732L5.87793 1.80273L6.04297 2.95801L3.62451 3.15479L5.19873 5.22412L4.10693 5.8208L2.98975 3.52295L1.97412 5.8208L0.856934 5.22412L2.39307 3.15479L0 2.95801L0.184082 1.80273L2.66602 2.50732L2.39307 0H3.62451Z"
                    fill="#415364"
                  />
                </svg>
              </div>
            </div>
            <div
              id="ccexp"
              className={classnames(styles.paymentInputInput, {
                [styles.errorDiv]: !!pageError.ccexp,
              })}
            ></div>
          </div>

          <div id="payButton"></div>

          <div className={styles.required}>*Required</div>

          {topError && <div className={styles.errorText}>{topError}</div>}

          <div
            className={styles.pci}
            onClick={() => {
              setShowPci(!showPci);
            }}
          >
            <LockIcon className={styles.lockIcon} />
            Why is this safe?
          </div>
          {showPci && (
            <div
              className={styles.pci}
              onClick={() => {
                setShowPci(!showPci);
              }}
            >
              World Wide Group has completed the required procedures regarding
              its policies, procedures, and technical systems that store,
              process, and/or transmit cardholder data to achieve full
              compliance with the Payment Card Industry Data Security Standard
              (PCI DSS).
            </div>
          )}
        </div>
        {visible && (
          <FooterModal
            visible={visible}
            type="submit"
            id="payButton"
            onCancel={() => setVisible(false)}
            onClick={() => handleClick()}
          />
        )}
      </form>
      <div style={{ display: "none" }} ref={ref} />
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <div>
              <NextButton
                title={"Next"}
                arrow={"right"}
                onClick={handleSubmit}
              />
            </div>
            <button
              style={{ display: "none" }}
              ref={buttonRef}
              onClick={buttonClick}
            />
          </div>
        </div>
      </div>
    </>
  );
}

Payment.propTypes = {
  zipCode: PropTypes.string,
  setZipCode: PropTypes.func,
  nickname: PropTypes.string,
  setNickname: PropTypes.func,
  cardNumber: PropTypes.string,
  setCardNumber: PropTypes.func,
  expiration: PropTypes.string,
  setExpiration: PropTypes.func,
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBack: PropTypes.func, // function passed in for back button
  onClick: PropTypes.func, // function passed in for next button
};
