import { useMutation, useQuery } from "react-query";
import axios from "axios";
import environment from "shared/environment";
import { createContainer } from "unstated-next";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosRetry from "axios-retry";

/**
 * Validates users name and password credentials and inserts them if they're valid.
 */
export function useSignUp() {
  // const [signUpValue, setSignUpValue] = useState({iboNum: null});
  axiosRetry(axios, { retries: 3 });

  const navigate = useNavigate();
  const [showFooter, setShowFooter] = useState(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);

  const signUpValueRef = useRef({ iboNum: null });
  const setSignUpValue = (value) => {
    signUpValueRef.current = value;
  };

  const apiBase = environment.apiBase;
  const coreApiUrl = environment.coreApiUrl;

  useEffect(() => {
    getStates.mutate(); //fetch countries and states data
  }, []);

  const { data: date, status: dateStatus } = useQuery("date", async () => {
    return await axios(
      apiBase + `/api/public/trial-end`,
      // "http://localhost:3750/api/public/trial-end",
      {
        method: "GET",
      }
    ).then((res) => res.data);
  });

  const { data: settings, status: settingsStatus } = useQuery(
    "settings",
    async () => {
      return await axios(apiBase + `/api/public/app-settings`, {
        method: "GET",
      }).then((res) => res.data);
    }
  );

  const maintainDreamStreamLosByIbo = useMutation(async (input) => {
    return axios(apiBase + `/api/public/maintain-dreamstream-ibo/${input}`, {
      method: "post",
    })
      .then((rsp) => rsp.data)
      .catch((err) => err);
  });

  const {
    data: code,
    status: codeStatus,
    refetch: fetchCode,
  } = useQuery(
    "code",
    async () => {
      // Try pulling token from localStorage first.
      let token = null;
      token = localStorage.getItem("wwg-token")
        ? decodeURIComponent(localStorage.getItem("wwg-token"))
        : null;

      // If not present, try extracting value from cookie.
      if (!token) {
        const cookies = document.cookie.split(";").reduce((res, c) => {
          const [key, val] = c.trim().split("=").map(decodeURIComponent);
          const allNumbers = (str) => /^\d+$/.test(str);
          try {
            return Object.assign(res, {
              [key]: allNumbers(val) ? val : JSON.parse(val),
            });
          } catch (e) {
            return Object.assign(res, { [key]: val });
          }
        }, {});
        if (cookies["wwg-token"]) {
          token = cookies["wwg-token"];
        }
      }

      if (token) {
        return await axios(
          apiBase + `/api/public/invite`,
          // "http://localhost:3750/api/public/invite",
          {
            method: "GET",
            headers: { "wwg-token": token },
          }
        ).then((res) => res.data);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const [inviteError, setInviteError] = useState(null);
  const inviteCodePut = useMutation(
    async (data) => {
      const res = await axios(
        apiBase + `/api/public/invite/use`,
        // "http://localhost:3750/api/public/invite/use",
        {
          method: "put",
          data: data,
        }
      );
      return res.data;
    },
    { onError: (e) => setInviteError(e.response.data.message) }
  );

  const currencyFormatter = useMemo(() => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter;
  }, []);

  const lookupIBO = useMutation(async (input) => {
    return axios(apiBase + `/api/public/lookup/${input.ibo}/${input.type}`, {
      method: "get",
    }).then((rsp) => rsp.data);
    // test
    // return axios(`http://localhost:3750/api/public/lookup/${input.ibo}/${input.type}`, {
    //   method: "get",
    // }).then((rsp) => rsp.data);
  });

  const lookupSponsorPlatinumIBO = useMutation(async (input) => {
    return axios(
      apiBase +
        `/api/public/lookup/${input.ibo}/${input.sponsor}/${input.platinum}`,
      {
        method: "get",
      }
    ).then((rsp) => rsp.data);
  });

  const getStates = useMutation(async () => {
    return axios(coreApiUrl + `/v1/shipping/address/states`, {
      method: "get",
    }).then((res) => {
      let data = res.data;
      setCountries(data);
      return data;
    });
  });

  const validateContactInfo = useMutation(async (contact) => {
    return axios(apiBase + `/api/public/validate-info`, {
      method: "post",
      data: contact,
    }).then((res) => res.data);
  });

  const validateCredentials = useMutation(async (info) => {
    const data = {
      id: info.id,
      username: info.username,
      password: info.password,
    };
    return axios(apiBase + `/api/public/validate-credentials`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  const validateAddress = useMutation(async (address) => {
    const data = {
      address: {
        city: address.city,
        country: address.country,
        line1: address.line1,
        line2: address.line2,
        postalCode: address.postalCode,
        recipient: "",
        state: address.state,
      },
      performGeolocation: true,
      performTaxCodes: true,
      performValidation: true,
      recipient: "",
      showRecipient: true,
    };
    return axios(coreApiUrl + `/v1/shipping/address/resolve`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  const getAvailableSubscriptions = useMutation(async (address) => {
    const data = {
      city: address.city,
      country: address.country,
      line1: address.line1,
      line2: address.line2,
      postalCode: address.postalCode,
      state: address.state,
    };
    return axios(
      apiBase + `/api/public/subscription/${signUpValueRef.current.iboNum}`,
      {
        method: "POST",
        data: data,
      }
    ).then((res) => res.data);
  });

  const validateIBO = useMutation(async () => {
    return axios(
      apiBase + `/api/public/validate/${signUpValueRef.current.iboNum}`,
      {
        method: "GET",
      }
    ).then((res) => res.data);
  });

  const addCustomerPublic = useMutation(async () => {
    const data = {
      firstName: signUpValueRef.current.mainIBO.firstName,
      lastName: signUpValueRef.current.mainIBO.lastName,
      ibo: signUpValueRef.current.iboNum,
      paymentToken: signUpValueRef.current.paymentToken,
    };
    return axios(coreApiUrl + `/v1/billing/glacier/add-customer-public`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  /*
  {"iboNumber":"1000","nameFirst":"Test","nameMiddle":"","nameLast":"Test","email":"test@test.com","primaryAddress1":"5601 E SPRAGUE AVE","primaryAddress2":"","primaryCity":"SPOKANE","primaryState":"WA","primaryZip":"99212-0826","primaryCountry":"US","primaryLatitude":47.658373,"primaryLongitude":-117.333296,"primaryVerified":1,"primaryTaxCode_WA":-1,"shippingAddress1":"5601 E SPRAGUE AVE","shippingAddress2":"","shippingCity":"SPOKANE","shippingState":"WA","shippingZip":"99212-0826","shippingCountry":"US","shippingLatitude":47.658373,"shippingLongitude":-117.333296,"shippingVerified":1,"shippingTaxCode_WA":-1,"secondaryNameFirst":"","secondaryNameMiddle":"","secondaryNameLast":"","phoneAC":"123","phonePrefix":"456","phoneSuffix":"7890"}
  */
  const insertContact = useMutation(async () => {
    // 1 -> primaryContactInsert

    let phoneAC = "";
    let phonePrefix = "";
    let phoneSuffix = "";

    // because of how data is inserted and kept in the phone numbers table, we use the same substring format between US and AU
    if (
      environment.WEB_APP_COUNTRY === "US" ||
      environment.WEB_APP_COUNTRY === "AU"
    ) {
      phoneAC = signUpValueRef.current.mainIBO.phoneNumber.substring(0, 3);
      phonePrefix = signUpValueRef.current.mainIBO.phoneNumber.substring(3, 6);
      phoneSuffix = signUpValueRef.current.mainIBO.phoneNumber.substring(6);
    }
    // else if (environment.WEB_APP_COUNTRY === "AU") {
    //   phoneAC = signUpValueRef.current.mainIBO.phoneNumber.substring(0, 2);
    //   phonePrefix = signUpValueRef.current.mainIBO.phoneNumber.substring(2, 6);
    //   phoneSuffix = signUpValueRef.current.mainIBO.phoneNumber.substring(6);
    // }
    else if (environment.WEB_APP_COUNTRY === "CN") {
      phoneAC = signUpValueRef.current.mainIBO.phoneNumber.substring(0, 3);
      phonePrefix = signUpValueRef.current.mainIBO.phoneNumber.substring(3, 7);
      phoneSuffix = signUpValueRef.current.mainIBO.phoneNumber.substring(7);
    }

    const data = {
      iboNumber: signUpValueRef.current.iboNum,
      nameFirst: signUpValueRef.current.mainIBO.firstName,
      nameMiddle: signUpValueRef.current.mainIBO.middleName,
      nameLast: signUpValueRef.current.mainIBO.lastName,

      phoneAC: phoneAC,
      phonePrefix: phonePrefix,
      phoneSuffix: phoneSuffix,

      email: signUpValueRef.current.mainIBO.email,

      primaryAddress1: signUpValueRef.current.selectedAddress.line1,
      primaryAddress2: signUpValueRef.current.selectedAddress.line2,
      primaryCity: signUpValueRef.current.selectedAddress.city,
      primaryState: signUpValueRef.current.selectedAddress.state,
      primaryZip: signUpValueRef.current.selectedAddress.postalCode,
      primaryCountry: signUpValueRef.current.selectedAddress.country,
      primaryLatitude: signUpValueRef.current.selectedAddress.latitude,
      primaryLongitude: signUpValueRef.current.selectedAddress.longitude,
      primaryVerified: signUpValueRef.current.primaryVerified,
      primaryTaxCode_WA: -1,

      shippingAddress1: signUpValueRef.current.selectedAddress.line1,
      shippingAddress2: signUpValueRef.current.selectedAddress.line2,
      shippingCity: signUpValueRef.current.selectedAddress.city,
      shippingState: signUpValueRef.current.selectedAddress.state,
      shippingZip: signUpValueRef.current.selectedAddress.postalCode,
      shippingCountry: signUpValueRef.current.selectedAddress.country,
      shippingLatitude: signUpValueRef.current.selectedAddress.latitude,
      shippingLongitude: signUpValueRef.current.selectedAddress.longitude,
      shippingVerified: signUpValueRef.current.primaryVerified,
      shippingTaxCode_WA: -1,

      secondaryNameFirst: "",
      secondaryNameMiddle: "",
      secondaryNameLast: "",
    };
    return axios(apiBase + `/api/account/contact/insert`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  /*
  {"cardHolder":"Test Test","expiration":"0223","firstSix":"411111","ibo":"1000","iboToken":"jUGl+QqZLNVSTBf3nPrHSQ==","isPrimary":true,"lastFour":"1111","nickname":"asda","postalCode":"4444","token":"7500000000001479"}
  */
  const addCardPublic = useMutation(async () => {
    // 2 -> paymentInfoInsert
    const data = {
      cardHolder:
        signUpValueRef.current.mainIBO.firstName +
        " " +
        signUpValueRef.current.mainIBO.lastName,
      expiration: signUpValueRef.current.exp,
      firstSix: signUpValueRef.current.firstSix,
      ibo: signUpValueRef.current.iboNum,
      iboToken: signUpValueRef.current.iboToken,
      isPrimary: true,
      lastFour: signUpValueRef.current.lastFour,
      nickname: signUpValueRef.current.cardNickname,
      postalCode: signUpValueRef.current.cardZipCode,
      tokenization: signUpValueRef.current.paymentToken,
      token: signUpValueRef.current.vaultId,
    };
    return axios(coreApiUrl + `/v1/billing/glacier/add-card-public`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  /*
  {"ibo":"1000","membershipCreditCardId":622695,"type":12,"signUpTransaction":{"ibo":"1000","membershipCreditCardId":622695,"membership":"Premier Membership + Digital Delivery","address":{"suggestions":[],"isModified":true,"isValid":true,"washingtonTaxCode":-1,"city":"SPOKANE","country":"US","latitude":47.658373,"line1":"5601 E SPRAGUE AVE","line2":null,"longitude":-117.333296,"postalCode":"99212-0826","state":"WA","stateInfo":{"abbreviation":"WA","name":"Washington","stateId":73,"type":0},"countryInfo":{"abbreviation":"US","countryId":220,"name":"United States"},"isConfirmed":true,"recipient":""}},"webAccount":{"iboNumber":"1000","username":"tester1000","password":"Testing1!","remember":"","accountType":12,"accountStatus":6},"emergencyContact":{"firstName":"","lastName":"","phone":""},"guid":"2e1a856d-03e9-4884-8352-dcdc429a60a5"}
  */
  const processPayment = useMutation(async () => {
    // 3 -> processPayment

    const signUpTransaction = {
      ibo: signUpValueRef.current.iboNum,
      membership: signUpValueRef.current.selectedSubscription.title,
      membershipCreditCardId: signUpValueRef.current.membershipCreditCardId,
      address: {
        line1: signUpValueRef.current.selectedAddress.line1,
        line2: signUpValueRef.current.selectedAddress.line2,
        city: signUpValueRef.current.selectedAddress.city,
        state: signUpValueRef.current.selectedAddress.state,
        postalCode: signUpValueRef.current.selectedAddress.postalCode,
        country: signUpValueRef.current.selectedAddress.country,
      },
    };

    const webAccount = {
      iboNumber: signUpValueRef.current.iboNum,
      username: signUpValueRef.current.username,
      password: signUpValueRef.current.password,
      remember: "",
      accountType: signUpValueRef.current.selectedSubscription.type,
      accountStatus: 6, //expired
    };

    const emergencyContact =
      signUpValueRef.current.emergency &&
      (signUpValueRef.current.emergency.firstName ||
        signUpValueRef.current.emergency.lastName ||
        signUpValueRef.current.emergency.phoneNumber)
        ? {
            firstName: signUpValueRef.current.emergency.firstName,
            lastName: signUpValueRef.current.emergency.lastName,
            phone: signUpValueRef.current.emergency.phoneNumber.replace(
              /[^0-9]+/g,
              ""
            ),
          }
        : null;

    const data = {
      ibo: signUpValueRef.current.iboNum,
      membershipCreditCardId: signUpValueRef.current.membershipCreditCardId,
      type: signUpValueRef.current.selectedSubscription.type,
      signUpTransaction: signUpTransaction,
      webAccount: webAccount,
      emergencyContact: emergencyContact,
      guid: signUpValueRef.current.selectedSubscription.guid,
    };

    return axios(apiBase + `/api/subscription/process`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  const createCommunityUser = useMutation(async () => {
    // 4 -> createCommunityUser
    return axios(
      apiBase + `/api/public/user/create/${signUpValueRef.current.iboNum}`,
      {
        method: "POST",
      }
    ).then((res) => res.data);
  });

  //{"ibo":"1000","firstName":null,"middleName":null,"lastName":null,"email":"","phone":""}
  const addCoibo = useMutation(async () => {
    // 5 -> addCoIBO
    const data = {
      ibo: signUpValueRef.current.iboNum,
      firstName: signUpValueRef.current.coIBO.firstName,
      middleName: signUpValueRef.current.coIBO.middleName,
      lastName: signUpValueRef.current.coIBO.lastName,
      phone: signUpValueRef.current.coIBO.phoneNumber,
      email: signUpValueRef.current.coIBO.email,
    };

    return axios(apiBase + `/api/public/coibo`, {
      method: "POST",
      data: data,
    }).then((res) => res.data);
  });

  const configureGlacier = () => {
    window.CollectJS.configure({
      variant: "inline",
      styleSniffer: true,
      theme: "bootstrap",
      primaryColor: "#ADB7C0",
      secondaryColor: "#ADB7C0",
      googleFont: "Open Sans:400",
      buttonText: "Add Card",
      paymentType: "cc",
      fields: {
        cvv: "hide",
        ccnumber: {
          selector: "#ccnumber",
          placeholder: " Credit Card Number",
        },
        ccexp: {
          selector: "#ccexp",
          placeholder: " MM/YY",
        },
      },
      callback: async (response) => {
        setSignUpValue({
          ...signUpValueRef.current,
          paymentToken: response.token,
        });

        // check if same month
        const today = new Date();
        let month = today.getMonth() + 1;
        let year = today.getYear() - 100;

        if (Number(`${month}${year}`) === Number(response.card.exp)) {
          setShowLoadingIcon(false);
          setOneError(
            "Payment",
            "ccexp",
            "Cards expiring this month are invalid"
          );
          return;
        }

        setOneError("Payment", "default", "");

        let validated = await validateIBO.mutateAsync().catch((error) => {
          let rsp = error.response;

          let errorMessage = "";

          if (rsp.data && rsp.data.message) {
            errorMessage = rsp.data.message;
          } else if (rsp.data && rsp.data.error) {
            errorMessage = rsp.data.error;
          } else {
            errorMessage =
              "Temporary errors occurred while validating IBO. Please try again or contact the Technical Support via e-mail at support@wwdb.com. Thank you.";
          }

          return {
            error: {
              error_message: errorMessage,
            },
          };
        });

        if (validated.error) {
          setOneError("Payment", "default", validated.error.error_message);
          setShowLoadingIcon(false);
          return;
        }

        if (!validated.iboToken) {
          setOneError(
            "Payment",
            "default",
            "Temporary errors occurred while generating IBO Token. Please try again or contact the Technical Support via e-mail at support@wwdb.com. Thank you."
          );
          setShowLoadingIcon(false);
          return;
        }

        setSignUpValue({
          ...signUpValueRef.current,
          iboToken: validated.iboToken,
        });

        const acceptableCards = [
          "diners",
          "discover",
          "jcb",
          "mastercard",
          "visa",
        ];

        if (
          !acceptableCards.includes(response.card.type.toString().toLowerCase())
        ) {
          setOneError(
            "Payment",
            "default",
            "Card not accepted. Please enter a valid Visa®, Mastercard®, Discover®, JCB or Diners Club International®."
          );
          setShowLoadingIcon(false);
          return;
        }

        // DEBUG
        // console.log(response);

        //POST https://app-core-web-dev.wwdb.com/v1/billing/glacier/add-customer-public
        /*
          {"firstName":"Test","ibo":"1000","lastName":"Test","paymentToken":"F7drz2Fx-2XtAu6-P54af6-AZc32j4nguU4"}
          */

        let _vaultId = "";
        let rspFromCore = null;
        rspFromCore = await addCustomerPublic.mutateAsync().catch((error) => {
          let rsp = error.response;

          let errorMessage = "";

          if (rsp.data && rsp.data.Message) {
            let messageFragment = rsp.data.Message.split("REFID");
            let _error =
              messageFragment.length > 1
                ? `${messageFragment[0].trim()}.`
                : rsp.data.Message;
            //M is capital
            errorMessage = _error;
          } else {
            errorMessage = error;
          }

          // ignore failed validation if JCB since not supported
          if (response.card.type.toString().toLowerCase() !== "jcb") {
            return {
              error: {
                error_message: errorMessage,
              },
            };
          } else {
            _vaultId = rsp.data.Message.split("token=")[1];
            return;
          }
        });

        setShowLoadingIcon(false);

        if (rspFromCore && !rspFromCore.error) {
          setSignUpValue({
            ...signUpValueRef.current,
            vaultId: rspFromCore.vaultId,
            firstSix: rspFromCore.cardFirstSix,
            lastFour: rspFromCore.cardLastFour,
            exp: rspFromCore.cardExpiration,
          });
          setShowFooter(true);
        } else if (response.card.type.toString().toLowerCase() === "jcb") {
          setSignUpValue({
            ...signUpValueRef.current,
            vaultId: _vaultId,
            firstSix: response.card.bin,
            lastFour: response.card.number.substring(
              response.card.number.length,
              response.card.number.length - 4
            ),
            exp: response.card.exp,
          });
          setShowFooter(true);
        } else {
          setOneError("Payment", "default", rspFromCore.error.error_message);
        }
      },
      validationCallback: function (field, status, message) {
        //2field: ccnumber & ccexp => js_ccnumber & js_ccexp
        if (status) {
          setOneError("Payment", "js_" + field, "");
        } else {
          setOneError("Payment", "js_" + field, message);
        }
      },
      fieldsAvailableCallback: function () {
        setOneError("Payment", "js_ccnumber", "Please enter Card number");
        setOneError("Payment", "js_ccexp", "Please enter Expiration");
      },
    });
  };

  const getTokenizationKey = useMutation(async () => {
    return axios(`${coreApiUrl}/v1/billing/glacier/key`, {
      method: "get",
    }).then((key) => {
      if (window.CollectJS == null) {
        const tokenization = key.data;

        let script = document.createElement("script");
        script.src = "https://secure.glacierpaygateway.com/token/Collect.js";
        script.setAttribute("data-tokenization-key", tokenization);
        script.type = "text/javascript";
        script.async = false;
        script.charset = "utf-8";

        script.onload = function () {
          configureGlacier();
        };

        document.getElementsByTagName("body")[0].appendChild(script);
      } else {
        configureGlacier(); //check callback
      }
    });
  });

  const loadReceipt = useMutation(async ({ receiptId, iboNum }) => {
    /*
    GET https://app-membership-signup-web-dev.wwdb.com/api/receipt/membership/7110770/1000
    {"confirmation":"1000-M4837672-D2410984","ibo":1000,"items":[{"Amount":0.0000,"Description":"Premier Membership","Note":"$59.95 Monthly | Prorated Credit: $59.95"},{"Amount":0.0000,"Description":"Digital Delivery","Note":"$30.00 Monthly | Prorated Credit: $30.00"}],"name":"Test & TEST RYANN","merchant":25,"paymentDescription":"VISA: ****1111 Exp: 03/33 Zip: 88888","processedOn":"2023-01-05T01:00:02.587","renewalOn":"2023-02-05T00:00:00","status":1,"total":0.0000}
    */

    return axios(
      `${apiBase}/api/receipt/membership/${
        receiptId || signUpValueRef.current.receiptId
      }/${iboNum || signUpValueRef.current.iboNum}`,
      {
        method: "get",
      }
    ).then((res) => res.data);
  });

  const makePurchase = async () => {
    // 1 -> primaryContactInsert
    // 2 -> paymentInfoInsert
    // 3 -> processPayment
    // 4 -> createCommunityUser
    // 5 -> addCoIBO
    // 6 -> allset

    let purchaseCheckpoint = { name: "", code: "", err: "" };

    try {
      // 1 -> primaryContactInsert
      const insertRsp = await insertContact.mutateAsync().catch((err) => {
        //TODO
        purchaseCheckpoint = {
          name: "contact",
          code: "M001",
          err: err.response.data?.error,
        };
      });

      if (insertRsp == null) {
        //TODO
        return purchaseCheckpoint;
      }

      if (
        !signUpValueRef ||
        !signUpValueRef.current ||
        !signUpValueRef.current.noCard
      ) {
        // 2 -> paymentInfoInsert
        const publicCardRsp = await addCardPublic.mutateAsync().catch((err) => {
          //TODO
          purchaseCheckpoint = {
            name: "info",
            code: "M002",
            err: err.response.data?.error,
          };
        });

        if (!publicCardRsp) {
          //TODO
          return purchaseCheckpoint;
        }

        setSignUpValue({
          ...signUpValueRef.current,
          membershipCreditCardId: publicCardRsp.membershipCreditCardId,
          cardIsPrimary: publicCardRsp.isPrimary,
        });
      } else {
        //TODO No Card
        setSignUpValue({
          ...signUpValueRef.current,
          membershipCreditCardId: 0,
          cardIsPrimary: false,
        });
      }

      // 3 -> processPayment
      const processPaymentRsp = await processPayment
        .mutateAsync()
        .catch((err) => {
          //TODO
          purchaseCheckpoint = {
            name: "process",
            code: "M003",
            err: err.response.data?.error,
          };
        });

      if (!processPaymentRsp) {
        //TODO
        return purchaseCheckpoint;
      }

      setSignUpValue({
        ...signUpValueRef.current,
        receiptId: processPaymentRsp,
      });

      // 4 -> createCommunityUser
      const userRsp = await createCommunityUser.mutateAsync().catch((err) => {
        purchaseCheckpoint = {
          name: "community",
          code: "M004",
          err: err.response.data?.error,
        };
      });

      //204 No Content
      if (userRsp == null) {
        return purchaseCheckpoint;
      }

      if (
        signUpValueRef.current.coIBO &&
        signUpValueRef.current.coIBO.email &&
        signUpValueRef.current.coIBO.email.length > 0
      ) {
        // 5 -> addCoIBO
        const coIboRsp = await addCoibo.mutateAsync().catch((err) => {
          purchaseCheckpoint = {
            name: "coibo",
            code: "M005",
            err: err.response.data?.error,
          };
        });

        //204 No Content
        if (coIboRsp == null) {
          return purchaseCheckpoint;
        }
      }

      await maintainDreamStreamLosByIbo
        .mutateAsync(signUpValueRef.current.iboNum)
        .catch((err) => {
          purchaseCheckpoint = {
            name: "maintaindreamstreamLOSbyIBO",
            code: "M006",
            err: err.error ? err.error : err,
          };
        });

      //allset
      purchaseCheckpoint = {
        name: "allset",
        code: "",
        err: "",
      };
    } catch (error) {
      //Ignore
    }

    return purchaseCheckpoint;
  };

  const setOneError = (page, field, message) => {
    setError((prev) => {
      let current = prev ? prev[page] || {} : {};

      current = { ...current, [field]: message };

      return { ...prev, [page]: current };
    });
  };

  const clearPageError = (page) => {
    if (error) setError((prev) => ({ ...prev, [page]: {} }));
  };

  return {
    settings,
    settingsStatus,
    error,
    setOneError,
    clearPageError,
    lookupIBO,
    validateIBO,
    maintainDreamStreamLosByIbo,
    lookupSponsorPlatinumIBO,
    validateContactInfo,
    validateCredentials,
    validateAddress,
    getAvailableSubscriptions,
    getTokenizationKey,
    signUpValueRef,
    setSignUpValue,
    showFooter,
    setShowFooter,
    makePurchase,
    loadReceipt,
    currencyFormatter,
    showLoadingIcon,
    setShowLoadingIcon,
    countries,
    code,
    codeStatus,
    fetchCode,
    inviteCodePut,
    inviteError,
    date,
    dateStatus,
    configureGlacier,
  };
}

export const SignUpContainer = createContainer(useSignUp);
