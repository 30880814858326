import { Button } from "components/Button";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { HeaderModal } from "components/HeaderModal";
import { SignUpContainer } from "hooks/useSignUp";
import environment from "shared/environment";
import { useSessionStorage } from "../../hooks/UseSessionStorage/useSessionStorage";
import { ReactComponent as Help } from "./Help.svg";
import { ReactComponent as Alert } from "./Alert_Icon.svg";

import mobile from "mobileBridge";

import NextButton from "components/NextButton";

/**
 * Fist page of membership sign up. IBO number is entered and validated here before they can move on to the next steps.
 */

export default function Login() {
  const WEB_APP_COUNTRY = environment.WEB_APP_COUNTRY;
  // const defaultSponsorPlatinumInput =
  //   WEB_APP_COUNTRY === "AU" && environment.ENABLE_PLATINUM_SPONSOR_INPUT;
  const defaultSponsorPlatinumInput = false;
  const helpUrl = environment.WWG_HELP_URL;

  const {
    lookupIBO,
    lookupSponsorPlatinumIBO,
    setSignUpValue,
    signUpValueRef,
    setShowLoadingIcon,
    inviteCodePut,
    inviteError,
    settings,
    settingsStatus,
  } = SignUpContainer.useContainer();

  let { code } = useParams();

  const handleInputCodeUse = async () => {
    setShowLoadingIcon(true);

    await inviteCodePut.mutateAsync({ code: code, iboNumber: ibo });
  };

  const navigate = useNavigate();

  const [enableSponsorInput, setEnableSponsorInput] = useState(
    defaultSponsorPlatinumInput
  );
  const [enablePlatinumInput, setEnablePlatinumInput] = useState(
    defaultSponsorPlatinumInput
  );
  const [ibo, setIbo] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [platinum, setPlatinum] = useState("");

  const [enableAmwayValidation, setEnableAmwayValidation] = useState(false);

  useEffect(() => {
    settingsStatus && settingsStatus === "error" && setShowLoadingIcon(false);
  }, [settingsStatus]);

  useEffect(() => {
    if (settingsStatus === "loading") {
      setShowLoadingIcon(true);
    } else {
      setShowLoadingIcon(false);
    }
    if (settings) {
      setEnableAmwayValidation(
        settings.ENABLE_AMWAY_IBO_VALIDATION.toLowerCase() === "true"
      );
      setEnableSponsorInput(
        settings.ENABLE_PLATINUM_SPONSOR_INPUT.toLowerCase() === "true"
      );
      setEnablePlatinumInput(
        settings.ENABLE_PLATINUM_SPONSOR_INPUT.toLowerCase() === "true"
      );
    }
  }, [settings]);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [firstName, setFirstName] = useSessionStorage("firstName", "");
  const [lastName, setLastName] = useSessionStorage("lastName", "");
  const [middleName, setMiddleName] = useSessionStorage("middleName", "");
  const [secondaryFirstName, setSecondaryFirstName] = useSessionStorage(
    "secondaryFirstName",
    ""
  );
  const [secondaryMiddleName, setSecondaryMiddleName] = useSessionStorage(
    "secondaryMiddleName",
    ""
  );
  const [secondaryLastName, setSecondaryLastName] = useSessionStorage(
    "secondaryLastName",
    ""
  );

  const onChangeIBONum = (e) => {
    setIbo(e);
  };

  const onChangeSponsorNum = (e) => {
    setSponsor(e);
  };

  const onChangePlatinumNum = (e) => {
    setPlatinum(e);
  };

  const modalToggled = (errorAxios, errorMessage) => {
    if (errorMessage) {
      setModalMessage(errorMessage);
    } else {
      let genericMessage = errorAxios || "Something went wrong.";
      setModalMessage(genericMessage);
    }

    setIsOpen(true);
  };

  const [showConsentAlert, setShowConsentAlert] = useState(false);

  const onNext = async (code) => {
    setShowLoadingIcon(true);

    // validate types
    if (!Number(ibo)) {
      modalToggled("", "Please enter your IBO number.");
      setShowLoadingIcon(false);
      return;
    }
    if (!Number(sponsor)) {
      modalToggled("", "Please enter your Sponsor's IBO number.");
      setShowLoadingIcon(false);
      return;
    }

    if (enablePlatinumInput && !Number(platinum)) {
      modalToggled("", "Please enter your Platinum's IBO number.");
      setShowLoadingIcon(false);
      return;
    }

    let iboRsp = null;

    // validate ranges
    if (
      !(Number(ibo) >= 1 && Number(ibo) <= 999999999) &&
      !(Number(ibo) >= 7000000000 && Number(ibo) <= 7999999999)
    ) {
      // console.log(ibo);
      modalToggled(
        "",
        "The IBO number you entered is invalid. Please enter your personal IBO number and try again (not a Kate or mobile phone number). If this error continues, please contact your upline to verify you have the correct number."
      );
      setShowLoadingIcon(false);
      return;
    }

    if (
      enableSponsorInput &&
      !(Number(sponsor) >= 1 && Number(sponsor) <= 999999999) &&
      !(Number(sponsor) >= 7000000000 && Number(sponsor) <= 7999999999)
    ) {
      modalToggled(
        "",
        "The Sponsor IBO number you entered is invalid. Please enter your personal IBO number and try again (not a Kate or mobile phone number). If this error continues, please contact your upline to verify you have the correct number."
      );
      setShowLoadingIcon(false);
      return;
    }

    // check IBO num for existing account and amway API
    // TODO: IN PROGRESS - only phase 1 of API available
    if (enableAmwayValidation) {
      let iboValidationResp = await lookupIBO
        .mutateAsync({ ibo: ibo, type: "ibo" })
        .catch((err) => {
          modalToggled(err.response.data.error);
          setShowLoadingIcon(false);
        });

      if (!iboValidationResp) return;

      // check consent
      if (iboValidationResp.consent_provided.toLowerCase() === "no") {
        setShowConsentAlert(true);
        setShowLoadingIcon(false);
        return;
      }

      // check active
      if (iboValidationResp.status.toLowerCase() === "inactive") {
        setShowLoadingIcon(false);
        modalToggled("The Amway IBO associated with this IBO number is inactive.");
        return;
      }

      // validate sponsor if enabled
      if (enableSponsorInput) {
        let sponsorValidationResponse = await lookupIBO
          .mutateAsync({ ibo: sponsor, type: "sponsor" })
          .catch((err) => {
            modalToggled(err.response.data.error);
            setShowLoadingIcon(false);
          });

        if (!sponsorValidationResponse) {
          return;
        }
      }
    }

    if (enablePlatinumInput) {
      iboRsp = await lookupSponsorPlatinumIBO
        .mutateAsync({
          ibo: ibo,
          sponsor: sponsor,
          platinum: platinum,
        })
        .catch((e) => {
          modalToggled(e.message, e.response?.data?.error);
          setShowLoadingIcon(false);
        });
    }

    // if (!enablePlatinumInput || enableAmwayValidation) {
    //   iboRsp = await lookupIBO
    //     .mutateAsync({
    //       ibo: ibo,
    //     })
    //     .catch((e) => {
    //       modalToggled(e.message, e.response?.data?.error);
    //     });
    // }

    setShowLoadingIcon(false);

    if (!iboRsp?.ibo) return;

    setSignUpValue({ ...signUpValueRef.current, iboNum: iboRsp.ibo });

    if (iboRsp.primaryFirstName) setFirstName(iboRsp.primaryFirstName);

    if (iboRsp.primaryLastName) setLastName(iboRsp.primaryLastName);

    if (iboRsp.primaryMiddleInitial) setMiddleName(iboRsp.primaryMiddleInitial);

    if (iboRsp.secondaryFirstName)
      setSecondaryFirstName(iboRsp.secondaryFirstName);

    if (iboRsp.secondaryLastName)
      setSecondaryLastName(iboRsp.secondaryLastName);

    if (iboRsp.secondaryMiddleName)
      setSecondaryMiddleName(iboRsp.secondaryMiddleName);

    if (code && code.length === 12) await handleInputCodeUse();

    !inviteCodePut.isError && navigate("/welcome");
  };

  useEffect(() => {
    (inviteCodePut.isError || inviteCodePut.isSuccess) &&
      setShowLoadingIcon(false);
  }, [inviteCodePut]);

  const onCancel = () => {
    navigate("/");
  };

  return (
    <div className={styles.page}>
      {inviteCodePut.isError ? (
        <div className={styles.inviteError}>{inviteError}</div>
      ) : inviteCodePut.isPending ? null : (
        <>
          {settingsStatus === "success" ? (
            <>
              <HeaderModal
                visible={isOpen}
                info={modalMessage}
                onClick={() => setIsOpen(false)}
                btnTitle={"Close"}
              />
              <div className={styles.container}>
                <div className={styles.topRow}>
                  <div className={styles.backButton} onClick={onCancel}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.914 17.5L10.414 12L15.914 6.5L14.5 5.086L7.586 12L14.5 18.914L15.914 17.5Z"
                        fill="black"
                      />
                    </svg>{" "}
                    Create Your Account
                  </div>
                  <div className={styles.inputContainer}>
                    <div className={styles.iboNumber}>
                      <div className={styles.label}>
                        Enter Your Amway IBO Number
                      </div>
                      <div className={styles.label2}>
                        (Person registering with WWG):
                      </div>
                      <div className={styles.inputRow}>
                        <input
                          className={styles.input}
                          placeholder="Your IBO Number"
                          value={ibo}
                          onChange={(e) => onChangeIBONum(e.target.value)}
                        />
                        <div
                          className={styles.help}
                          onClick={() =>
                            modalToggled(
                              "",
                              <div className={styles.infoModalContainer}>
                                <div>
                                  This is the IBO number of the{" "}
                                  <span className={styles.bold}>
                                    NEW IBO registering for Premier Membership.
                                  </span>
                                </div>
                                <div>
                                  IBO Number is the number an IBO is assigned
                                  when they register with Amway. If you cannot
                                  remember your IBO Number, please contact your
                                  sponsor as they can look it up on amway.com
                                </div>
                              </div>
                            )
                          }
                        >
                          <svg
                            width="27"
                            height="26"
                            viewBox="0 0 27 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.52 1.264C15.934 1.264 18.2938 1.9523 20.301 3.24187C22.3082 4.53144 23.8726 6.36435 24.7964 8.50883C25.7202 10.6533 25.9619 13.013 25.4909 15.2896C25.02 17.5661 23.8575 19.6573 22.1506 21.2986C20.4436 22.9399 18.2688 24.0577 15.9012 24.5105C13.5336 24.9633 11.0795 24.7309 8.84921 23.8426C6.61895 22.9544 4.71272 21.4501 3.37157 19.5202C2.03042 17.5902 1.31459 15.3212 1.31459 13C1.31789 9.88839 2.60488 6.90514 4.89313 4.7049C7.18137 2.50466 10.284 1.26718 13.52 1.264ZM13.52 0.5C10.9489 0.5 8.43545 1.23311 6.29761 2.60663C4.15977 3.98015 2.49353 5.93238 1.50959 8.21646C0.525652 10.5005 0.268209 13.0139 0.769817 15.4386C1.27142 17.8634 2.50955 20.0907 4.32764 21.8388C6.14572 23.587 8.4621 24.7775 10.9839 25.2598C13.5056 25.7421 16.1195 25.4946 18.4949 24.5485C20.8703 23.6024 22.9007 22.0002 24.3291 19.9446C25.7576 17.889 26.52 15.4723 26.52 13C26.52 9.68479 25.1504 6.50537 22.7124 4.16117C20.2744 1.81696 16.9678 0.5 13.52 0.5Z"
                              fill="black"
                            />
                            <path
                              d="M11.8009 15.763L11.7572 15.221C11.7271 14.5915 11.8282 13.9625 12.0545 13.3711C12.2807 12.7797 12.6277 12.2377 13.0749 11.777C13.8257 11.1167 14.3199 10.2298 14.4758 9.26301C14.4758 8.38801 13.884 7.79101 12.6703 7.77701C11.895 7.77904 11.1367 7.99605 10.4863 8.40201L9.85089 6.55501C10.8823 5.96977 12.0626 5.67168 13.26 5.69401C15.964 5.69401 17.2037 7.20801 17.2037 8.88801C17.0794 10.27 16.4069 11.553 15.3255 12.471C14.9423 12.8426 14.6421 13.2856 14.4434 13.7728C14.2447 14.2599 14.1517 14.781 14.17 15.304V15.762L11.8009 15.763ZM12.8991 20.305C12.4572 20.287 12.0398 20.1045 11.7351 19.7962C11.4303 19.4879 11.2621 19.0778 11.2659 18.6525C11.2697 18.2271 11.4452 17.8199 11.7553 17.5166C12.0655 17.2133 12.486 17.0378 12.9283 17.027C13.3803 17.027 13.8139 17.1997 14.1336 17.5071C14.4532 17.8144 14.6328 18.2313 14.6328 18.666C14.6328 19.1007 14.4532 19.5176 14.1336 19.825C13.8139 20.1323 13.3803 20.305 12.9283 20.305H12.8991Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  {enableSponsorInput && (
                    <div className={styles.iboNumber}>
                      <div className={styles.label}>
                        Enter Your Sponsor's IBO Number:
                      </div>
                      <div className={styles.inputRow}>
                        <input
                          className={styles.input2}
                          placeholder="Your Sponsor's IBO Number"
                          value={sponsor}
                          onChange={(e) => onChangeSponsorNum(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {enablePlatinumInput && (
                    <div className={styles.iboNumber}>
                      <div className={styles.label}>
                        Enter Your Platinum's IBO Number:
                      </div>
                      <div className={styles.inputRow}>
                        <input
                          className={styles.input2}
                          placeholder="Your Platinum's IBO Number"
                          value={platinum}
                          onChange={(e) => onChangePlatinumNum(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {showConsentAlert && (
                    <div className={styles.alert}>
                      <div className={styles.alertRow}>
                        <Alert />
                      </div>
                      <div
                        className={[styles.alertRow, styles.altAlertColor].join(
                          " "
                        )}
                      >
                        We see that you have not given{" "}
                        <b>Approved Provider (AP)</b> Consent with Amway™.
                        Before you finalize registration with WWG™, you must
                        provide this consent.
                      </div>
                      <div className={styles.alertRow}>
                        <div className={styles.divider} />
                      </div>
                      <div
                        className={[styles.alertRow, styles.altAlertColor].join(
                          " "
                        )}
                      >
                        To provide your consent, click here:{" "}
                        <span
                          className={styles.link}
                          onClick={() => {
                            mobile.isApp()
                              ? mobile.openLink(
                                  "https://account.amway.com/my-account/approved-provider/WWG"
                                )
                              : window
                                  .open(
                                    "https://account.amway.com/my-account/approved-provider/WWG"
                                  )
                                  ?.focus();
                          }}
                        >
                          Amway Approved Provider Consent
                        </span>
                      </div>
                      <div className={styles.alertRow}>
                        After giving consent, you’ll be able to resume your WWG
                        registration.
                      </div>
                      <div className={styles.alertRow}>
                        <div className={styles.divider} />
                      </div>

                      <div
                        className={[
                          styles.alertRow,
                          styles.helpRow,
                          styles.altAlertColor,
                        ].join(" ")}
                      >
                        <Help
                          onClick={() => {
                            try {
                              window.zE.activate();
                            } catch {
                              modalToggled(
                                "",
                                "Please disable AdBlockers to use this feature."
                              );
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        We're here when you need us!
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.bottomRow}>
                  <div className={styles.center}>
                    <div className={styles.btnGroup}>
                      <NextButton
                        title={"Next"}
                        arrow={"right"}
                        className={styles.nextBtn}
                        onClick={() => {
                          onNext(code);
                        }}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                  <div className={styles.message}>
                    For IBO use only. Not to be used with Prospects.
                  </div>
                </div>
              </div>

              <div className={styles.footer}>
                {/* <div>V 5.0 (105)</div>
                <div>CR#73765</div> */}
              </div>
            </>
          ) : settingsStatus === "error" ? (
            <div className={styles.inviteError}>
              There was an issue while loading this page. Please contact
              Technical Support via e-mail at support@wwdb.com to resolve this
              issue. Thank you.
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
