import mobile from "mobileBridge";
import styles from "./index.module.css";

export default function USTerms() {
  return (
    <div
      className="WordSection1"
      style={{
        margin: "50px",
        maxWidth: "600px",
        margin: "auto",
        textAlign: "justify",
        lineHeight: "1em",
        padding: "10px",
      }}
    >
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "1.75pt",
          textAlign: "center",
          lineHeight: "214%",
        }}
      >
        <b>
          <span className={styles.header}>Terms and Conditions</span>
        </b>
      </p>
      <p className="MsoNormal" align="center">
        <span className={styles.subHeader}>
          Agreement to Use wwdb.com Website <br />
          and <br /> Dreambuilders Membership Terms & Conditions
        </span>
      </p>
      <div style={{ fontSize: "13px", fontWeight: "300" }}>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Welcome to Dreambuilders Membership,{" "}
            a Delaware LLC. This website is being
            used to implement the delivery of services to those World Wide
            Dreambuilders–affiliated Independent Business Owners (IBOs) desiring
            to enhance their educational skills for development of their Amway™
            business. The following terms and conditions will control the use of
            this website, including the mobile application, until further
            notification. By accessing this site and use of the services
            provided, you agree as a user to be bound by the terms and
            conditions of this Agreement.&nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            1.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Purpose of This Website and Mobile Application
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.5pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            This website and associated mobile application’s purpose is to
            inform you of useful information as a{" "}
            World Wide Dreambuilders–affiliated
            Amway™ IBO and is for your use in registering to acquire Business
            Support Materials. Premier Members may order Business Support
            Materials directly through this website and/or mobile application.
            If you have any questions regarding the site or mobile application,
            please contact Support under the “Help” tab on wwdb.com. If you have
            questions regarding the benefits, you should contact your upline
            Platinum.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            2.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Disclosure&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership is affiliated with World Wide Group™, LLC.
            Certain services and goods made available through Dreambuilders
            Membership are provided by contract with World Wide Group, LLC,
            and/or affiliates. There is intent to make a profit on these
            transactions. Furthermore, should Dreambuilders Membership generate
            a profit from its operations, such would be paid to its owners,
            including World Wide Group, LLC, and World Wide Internet Services,
            LLC.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Definitions of Service Plans&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.5pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <i>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Basic Member:
            </span>
          </i>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            {" "}
            A World Wide Dreambuilders–affiliated Amway™-powered IBO that has
            paid a fee of $5.00/month and has agreed to the terms of this
            Agreement.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.35pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <i>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Premier Member:
            </span>
          </i>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            {" "}
            A World Wide Dreambuilders–affiliated Amway™-powered IBO that has
            paid a fee of $64.95/month and has agreed to the terms of this
            Agreement.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <i>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Commissions Paid to Upline:
            </span>
          </i>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;Specific commissions have been designated for each Premier
            Membership month of services you purchase. These commissions are
            subject to modification. For each Premier Membership month you
            purchase, a commission is paid to your first-upline Premier Member
            who is a current fiscal year World Wide Dreambuilders and
            Amway™-qualified Platinum. This commission is paid to partially
            reimburse that Premier Membership–purchasing Platinum for his/her
            efforts in supplying downline and providing the necessary training,
            education, and support for his/her organization as required by
            Amway™. An additional commission is paid to your first-upline
            Premier Member who is a current fiscal year World Wide Dreambuilders
            and Amway™-qualified Emerald or Founders Ruby to partially reimburse
            the additional expenses associated with providing leadership to
            his/her Platinum organization along with training to much larger and
            more geographically distant group(s). For example, Organizational
            Opens sponsored by that specific Emerald; if a Premier
            Membership–purchasing Emerald has a downline Premier Member who is a
            current fiscal year World Wide Dreambuilders and Amway™-qualified
            Emerald, an additional commission is paid for each Premier
            Membership month (only that leg, not including any subsequent
            downline Emerald or higher qualified IBO).&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            4.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Online Conduct&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            The wwdb.com website may only be used for lawful purposes. This site
            must be used consistently with your obligations as an Independent
            Business Owner (“IBO”) powered by Amway™, including the Amway™ Rules
            of Conduct. Without limitation, this site may not be used for any
            unlawful, harmful, threatening, abusive, harassing, defamatory,
            vulgar, obscene, sexually explicit, profane, hateful, racially,
            ethnically, or otherwise objectionable material; including those
            acts which would violate any state or federal civil or criminal
            laws. If you have any questions about the use of this site, you
            should seek independent legal advice. At its discretion,
            Dreambuilders Membership may investigate any complaint arising as a
            result of the violation of this agreement and may act as necessary
            to (i) satisfy any law, regulation, government request or inquiry by
            Amway™; (ii) make any disclosure necessary or appropriate to operate
            this website; or (iii) to protect the rights or property
            Dreambuilders Membership, its members, officers, directors,
            employees, agents, third-party content providers, merchants,
            sponsors, and licensors or any other user of this website.
            Dreambuilders Membership reserves the right to prohibit conduct,
            communication, or content that it deems in its sole discretion to be
            harmful to any individual or entity, or which violates any
            applicable law. Notwithstanding the foregoing, Dreambuilders
            Membership cannot ensure prompt removal of questionable content.
            Dreambuilders Membership, its members, officers, directors,
            employees, agents or assign assumes no liability for any action or
            inaction with respect to conduct, communication, or content placed
            by a user on this website.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.2pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Proprietary Property of Dreambuilders Membership&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Should Dreambuilders Membership acquire or register any trademarks,
            patents, trade dress, trade signage, copyrights, or other incidents
            of proprietary property rights, you agree to be bound by the legal
            rights of Dreambuilders Membership and will not use such in any
            manner inconsistent with the rights of Dreambuilders Membership to
            the fullest extent of the law.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "0in",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            6.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Website Content&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "0in",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You acknowledge that the wwdb.com website contains proprietary
            content protected by copyrights, trademarks, trade secrets, or other
            proprietary rights laws, and that these rights are valid and
            protected in all forms including that of media and technologies
            existing now or hereinafter development. You may not modify, remove,
            delete, or otherwise use the content of this website in whole or
            part without the prior consent of Dreambuilders Membership. You may
            make copies of portions of this page for your personal use and
            files.&nbsp;&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            7.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Conditions for Premier Member Purchase of Business Support
            Materials&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.45pt",
            marginLeft: "35.75pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Pursuant to the Enhanced Amway Promise Program (“EAP”), and as more
            specifically set forth in the Quality Assurance Standards Program
            Standards, A World Wide Dreambuilders–affiliated Amway™-powered IBO
            represents and understands that there is no cost to become an IBO.
            In addition, IBO represents and understands that for IBOs in their
            first Amway™ contract year, as defined in the Amway™ Registration
            and Renewal Agreements, Approved Providers, such as World Wide
            Group, LLC, are not permitted to charge first contract year IBOs for
            any BSM. First contract year IBOs are not permitted to purchase BSM
            from an Approved Provider or an IBO. To the extent that World Wide
            Group, LLC, provides BSM to a first contract year IBO, those BSM
            will be complimentary. IBOs in their first contract year cannot be
            charged, directly or indirectly, for any BSM.&nbsp;&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.45pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Purchase of Business Support Materials as a Premier Member shall be
            conditioned upon your compliance with the terms of the
            current&nbsp;Amway™ Rules of Conduct.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.45pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            By registering as a Premier Member, you acknowledge that you have
            agreed to the Amway™ Rules of Conduct and are bound by its terms,
            INCLUDING THE OBLIGATION TO MEDIATE AND/OR ARBITRATE ANY DISPUTE as
            defined in Rule 11 of the Rules of Conduct. A copy of the Rules is
            available at&nbsp;
          </span>
          <span className="MsoHyperlink">
            <span style={{ fontFamily: '"Open Sans",serif' }}>
              www.amway.com/support/ordering-product-support/rules-of-conduct
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            . It is agreed that, for the purposes of compliance with the Amway™
            Rules of Conduct, your purchase of more than two items with the same
            stock number in a single order will be considered a transaction not
            for personal use. BSM returns and refunds are subject to the terms
            covered in Section 7 of the Amway™ Rules of Conduct:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "10.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            7.1.2.3.&nbsp;Except as otherwise provided in Rule 7.1, an IBO
            end-user of BSM may return BSM purchased, together with proof of
            purchase, within 180 days following purchase, for a refund on
            commercially reasonable terms.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Conditions for Resale of Business Support Materials{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.55pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Although a suggested retail price is provided, you may resell
            Business Support Materials acquired pursuant to this Agreement at
            the price you deem appropriate, subject to the Amway™ Rules of
            Conduct. If you have questions as to the downline IBO’s status, go
            to wwdb.com for a list of Premier Members in your part of the line
            of sponsorship.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            9.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Termination of Use&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You may terminate the services provided by this registration prior
            to the end of the month. If terminated earlier than the end of the
            month, services will remain available until the end of the month
            whether they are used or not. You agree to forfeit any fee paid for
            the remainder of that month in which you terminate services but
            elect not to use the services made available. Dreambuilders
            Membership may suspend or terminate your access to all or any part
            of this website and services, including any account thereon, without
            notice, for any reason in its sole discretion. If such suspension or
            termination were to occur, you will be refunded a prorated amount of
            the unused monthly fee. If you are a Complimentary Premier Member
            with an inactive account, you will have the ability to reinstate
            your account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Security of Your Username and Password&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You are responsible for keeping such username and account password
            confidential. You agree to accept full responsibility for all
            activities that occur within your Dreambuilders Membership account.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: ".25in",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            When and With Whom Do We Share Your Personal Information?
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "36.5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "36.5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Vendors, Consultants, and Other Third-Party Service Providers: We
            may share your data with third-party vendors, service providers,
            contractors, or agents (“third parties”) who perform services for us
            or on our behalf and require access to such information to do that
            work. We have contracts in place with our third parties, which are
            designed to help safeguard your personal information. This means
            that they cannot do anything with your personal information unless
            we have instructed them to do it. They will also not share your
            personal information with any organization apart from us. They also
            commit to protect the data they hold on your behalf and to retain it
            for the period we instruct.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "36.5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            In addition to the third parties listed below, we may also share
            your Amway™ and WWG™ data with your upline. This data sharing is
            necessary to support your business activities, provide guidance and
            assistance, and optimize your experience within our network.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "36.5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            The third parties we may share personal information with are as
            follows:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "74.75pt",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>·</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "116%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <b>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Content Optimization
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Amway™
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "74.75pt",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>·</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "116%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <b>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Data Backup and Security
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Amway™
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "74.75pt",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>·</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "116%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <b>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Functionality and Infrastructure Optimization
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "38.75pt",
            textIndent: "33.25pt",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Amway™
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "74.75pt",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>·</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "116%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <b>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              User Account Registration and Authentication
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "72.5pt",
            textIndent: "-.5pt",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Amway™
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "74.75pt",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>·</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "116%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <b>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Web and Mobile Analytics
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: "1.0in",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Amway™
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            12.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Disclaimer of Warranties&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            There are no warranties expressed or implied offered by
            Dreambuilders Membership. As provided in the Amway™ Rules of Conduct
            and in other terms of your Amway™ registration and renewal
            agreements, there is no guarantee as to the results of the services
            or goods purchased or the techniques, approaches, or attitudes
            suggested in Business Support Materials. You must never make any
            such claim of guarantee in the reselling of Business Support
            Materials. Your success depends on your own hard work. Goods sold by
            Dreambuilders Membership, to the fullest extent permissible by
            applicable law, are sold with a disclaimer as to all implied
            warranties, including but not limited to, implied warranties of
            merchantability and fitness for a particular purpose. Without
            limiting the foregoing, there is no representation or warranty of
            any kind, express or implied, that the website will be uninterrupted
            or error-free; or that the website, or email sent from or on behalf
            of Dreambuilders Membership, are free of viruses or defects. &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            13.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Limitation of Liability&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.55pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Under no circumstances shall Dreambuilders Membership, its members,
            its officers, its directors, its employees, and/or its agents be
            liable for any damages that result from the use of or inability to
            use this website, including but not limited to reliance by a user on
            any information obtained from the website, whether or not resulting
            from acts of God, communications failure, theft, destruction, or
            unauthorized access to records, programs, or services. You agree
            that Dreambuilders Membership, its members, its officers, its
            directors, its employees, and/or its agents will not be liable for
            any indirect, incidental, special, or consequential damages arising
            from the use of this website or the purchase of any services or
            goods. Certain state laws do not allow limitations on implied
            warranties or the exclusion or limitation of certain damages. If
            those state laws apply to you, some or all of the above disclaimers,
            exclusions, or limitations may not apply and you may have additional
            rights.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            14.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Applicable Law &amp; Jurisdiction&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Except to the extent the Federal Arbitration Act applies to the
            Dispute Resolution procedures set forth in Section 15, below, the
            terms and conditions of the use of this website, including the
            purchase of any goods or services, shall be controlled by the laws
            of the state of Michigan, without giving effect to any choice of law
            or conflicts of law rules or provisions (whether of the State of
            Michigan or any other jurisdiction) that would cause the application
            of the laws of any jurisdiction other than the State of
            Michigan.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            15.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dispute Resolution&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.55pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            All disputes, claims, or controversies arising out of or related to
            this Agreement; or the breach, termination, enforcement,
            interpretation, or validity thereof; or BSM, including, but not
            limited to, any claims or disputes between the parties or against
            another approved provider, IBOs, purchasers of BSM, or any
            signatories to this Agreement—whether they arise before or after the
            termination of this Agreement—shall be resolved in accordance with
            the Mediation and Arbitration processes set forth in Rule 11 of the
            current version of the Amway™ Rules of Conduct in effect at the time
            the dispute arises. It is understood that in the conduct of any
            Arbitration pursuant to Rule 11, the following shall be applicable:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "1.35pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.5.7. <i>Arbitrability Issues to Be Decided By Arbitrator:</i>
            &nbsp;The Arbitrator, and not any federal, state, or local court or
            agency, shall have exclusive authority to resolve any dispute
            relating to the interpretation, applicability, enforceability, or
            formation of this Agreement including, but not limited to, any claim
            that all or any part of this Agreement is void or voidable.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.5.8. <i>Award:</i> The Arbitrator’s award shall be limited to
            deciding the rights and responsibilities of the Parties in the
            specific dispute being arbitrated. The Arbitrator’s award shall have
            no collateral estoppel effect in any other proceeding. The
            Arbitrator shall not provide a statement of reasons for his or her
            award unless requested to do so by all Parties.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.5.9. <i>Consolidation:</i> Similar claims involving multiple
            Parties may be consolidated before a single Arbitrator if all
            Parties agree. The Arbitrator will decide any disputed consolidation
            issues.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.45pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.5.10. <i>Discovery:</i> Notwithstanding any discovery provisions
            in the JAMS or AAA commercial arbitration rules incorporated in
            paragraph 11.5.4, no discovery shall occur in an arbitration under
            these Rules unless and until specifically authorized by the
            Arbitrator. The Arbitrator shall decide the amount, scope, and
            timing of discovery as appropriate in each case. In addition, before
            requiring any discovery, the Parties involved in the arbitration
            shall agree on an appropriate confidentiality order consistent with
            the IBO Contract. If they fail to agree, the Arbitrator shall impose
            appropriate confidentiality requirements on the Parties and
            witnesses.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "102%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            This dispute resolution agreement is reciprocal and binds all
            parties and signatories to this Agreement.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "102%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            The parties, when involved in the dispute resolution process as
            parties, witnesses, or otherwise, will not disclose to any other
            person not directly involved in the dispute resolution
            process:&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            i. The substance of, or basis for, the claim or dispute;&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            ii. The content of any testimony or other information obtained
            through the dispute resolution process; OR{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            iii. The resolution (whether voluntary or not) or the terms of any
            resolution of any matter that is the subject of the dispute
            resolution process.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "102%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            However, nothing in this Agreement shall preclude a party or other
            signatory from, in good faith, investigating a claim or defense,
            including interviewing witnesses and otherwise engaging in
            discovery. Any dispute that may arise out of or that relates in any
            way to this Agreement must be resolved solely and exclusively in
            compliance with the dispute resolution procedures, including
            compulsory arbitration, set forth in Rule 11 of the Amway™ Rules of
            Conduct in effect at the time the dispute arose. A copy of Rule 11
            is found at&nbsp;
          </span>
          <span className="MsoHyperlink">
            <span style={{ fontFamily: '"Open Sans",serif' }}>
              www.amway.com
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;or contained with your copy of the Amway™ Rules of Conduct
            that you received when you registered as an IBO.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            16.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Indemnification&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            By use of this website, including the request for services offered,
            you agree to defend, indemnify, and hold harmless Dreambuilders
            Membership, its members, its officers, directors, employees,
            affiliates, and agents from and against any and all claims, damages,
            liability or expense, including costs and attorney fees, arising out
            of the use by you of this website or the request for services by you
            or any act in the use of this website that is otherwise inconsistent
            with your obligations as a Amway™-powered IBO, including without
            limitation the Rules of Conduct.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            17.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Modification and Severability&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.65pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership reserves the right to modify from time to
            time and without prior notice, the terms and conditions affecting
            the use of this website, the level of services provided, prices
            charged, as well as the benefits associated with any level of
            purchased services. These Terms and Conditions were last revised on
            May 2, 2024.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            DreamStream® User Agreement&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            DreamStream is a part of the World Wide Group Dreambuilders
            Membership. DreamStream is designed and intended to connect World
            Wide Group members within a Diamond organization on a professional
            business platform to enable them to be more productive and
            successful. These services are available through the World Wide
            Group website and mobile applications. You agree that by subscribing
            as a Dreambuilders Member, and by using DreamStream and the other
            features of the World Wide Group website, you are entering into a
            legally binding agreement with World Wide Group, LLC, based on the
            terms of this Agreement, and become a Dreambuilders Premier Member
            and DreamStream user.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>
            o
            <span style={{ font: '7.0pt "Open Sans"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1. Your Obligations:&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.1. &nbsp;All material you post on DreamStream must comply with
            the Amway™ Quality Assurance Standards and the Amway™ Rules of
            Conduct, as may be amended from time to time with or without advance
            notice, and the policies and guidelines explained in the following
            sections.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.2. DreamStream is a platform in which business building and
            motivational content can be shared throughout a Diamond
            organization. It is not intended as a forum to express or share
            personal, religious, political, etc. views. All material posted may
            be blocked or removed by World Wide Group or the upline Diamond at
            their discretion.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.3. &nbsp;Any complaints regarding content posted on DreamStream
            will be handled by the upline Platinum or above and World Wide
            Group. Repeated complaints or posting of materials deemed to be
            inappropriate by either your upline Platinum or above, or by World
            Wide Group, may result in you being blocked from posting or
            accessing on DreamStream.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.4. It is Management Team policy that any potentially negative
            situation only be dealt with your upline.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.5. &nbsp;Do not abuse DreamStream by using it to spam, abuse,
            harass, or otherwise violate the User Agreement or Privacy
            Policy.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.1.6. &nbsp;You are solely responsible for all content you post on
            DreamStream, and agree that you will not violate any other agreement
            to which you are a party, and will not violate any intellectual
            property rights such as copyright or trademark rights. This includes
            any Amway™ images, product, or otherwise, and World Wide Group
            images that have copyright restrictions.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.2. <i>Indemnification</i>: &nbsp;You indemnify us and hold us
            harmless for all damages, losses, and costs (including, but not
            limited to, reasonable attorneys’ fees and costs) related to all
            third-party claims, charges, and investigations caused by (1) your
            failure to comply with this Agreement, including, without
            limitation, your submission of content that violates third-party
            rights or applicable laws, (2) any content you submit to
            DreamStream, and (3) any activity in which you engage in or through
            DreamStream.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.3. <i>Applications: </i>Dreambuilders Membership may offer
            services through applications built using the Worldwide Group
            website and DreamStream platform (“World Wide Group applications”).
            Examples of World Wide Group applications include its smart phone
            applications. If you use a World Wide Group application or interact
            with a website that has deployed a plugin, you agree that
            information about you and your use of the services, including, but
            not limited to, your device, your mobile carrier, your internet
            access provider, your physical location, and/or Web pages containing
            plugins that load in your browser may be communicated to us<i>.</i>{" "}
            Furthermore, by importing any of your Dreambuilders Membership data
            through the World Wide Group application, you represent that you
            have authority to share the transferred data with your mobile
            carrier or other access provider. In the event you change or
            deactivate your mobile account, you must promptly update your
            Dreambuilders Membership account information to ensure that your
            messages are not sent to the person that acquires your old number
            and failure to do so is your responsibility. You acknowledge you are
            responsible for all charges and necessary permissions related to
            accessing the World Wide Group website and applications through your
            mobile access provider. Therefore, you should check with your
            provider to find out if the services are available and the terms for
            these services for your specific mobile devices. Finally, by using
            any downloadable application to enable your use of the services, you
            are explicitly confirming your acceptance of the terms of the End
            User License Agreement associated with the application provided at
            download or installation, or as may be updated from time to time.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4. <i>Privacy:</i>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.1.&nbsp;You acknowledge that your submission of any
            information, statements, data, and content to us is voluntary on
            your part.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.2.&nbsp;We collect information:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            When you register with Dreambuilders Membership to become a Premier
            Member, such as your name, email, address, payment information, and
            a password;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            When you view and interact with World Wide Group Web pages,
            features, and functionality, including mobile applications;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Through cookies and other technologies that allow us to recognize
            you and customize your experience; and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            When you interact with third-party services available through the
            World Wide Group website.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.3.&nbsp;We use the information you provide to:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Administer your account with us and customize the service we provide
            to you and other users; and to
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Send you service or promotional communications through email and
            notices on the World Wide Group website.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.55pt",
            marginLeft: "108.5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.55pt",
            marginLeft: "1.25in",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            We&nbsp;DO NOT&nbsp;sell, rent, or otherwise provide personally
            identifiable information to third parties without your consent
            except where it is necessary to carry out your instructions (to
            process your payment information, for example). Please note that in
            order to fulfill your requests for paid services, we share your
            payment information with our payment processor.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.4.&nbsp;Your information choices are to:{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Review, enhance, or edit your personal information through your
            personal profile page;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Control the messages you receive from other users; and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Change your settings to control visibility and accessibility through
            our website.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            To update your privacy settings, go to
            wwdb.com&gt;Settings&gt;Privacy and check the data you would like to
            share.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.5.&nbsp;Security.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Personal information you provide will be secured in accordance with
            industry standards and technology. Because the Internet is not a
            100% secure environment, we cannot ensure or warrant the security of
            any information you transmit to Dreambuilders Membership. There is
            no guarantee that information may not be accessed, copied,
            disclosed, altered, or destroyed by breach of any of our physical,
            technical, or managerial safeguards.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "2.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You are responsible for maintaining the secrecy of your unique
            password and account information, and for controlling access to your
            email communications at all times.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.4.6.<i>&nbsp;Export Control</i>: Your use of Dreambuilders
            Membership services, including our software, is subject to export
            and re-export control laws and regulations, including the Export
            Administration Regulations (“EAR”) maintained by the United States
            Department of Commerce and sanctions programs maintained by the
            Treasury Department’s Office of Foreign Assets Control. You shall
            not—directly or indirectly—sell, export, re-export, transfer,
            divert, or otherwise dispose of any software or service to any
            end-user without obtaining the required authorizations from the
            appropriate government authorities. You also warrant that you are
            not prohibited from receiving U.S. origin products, including
            services or software.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.5.<i>&nbsp;Your Rights:</i>&nbsp;On the condition that you comply
            with all of your obligations under this Agreement, including, but
            not limited to, the list provided in the “Do’s and Don’ts” section,
            we grant you a limited, revocable, nonexclusive, nonassignable,
            nonsublicenseable license and right to access the services, through
            a generally available Web browser, mobile device, or application
            (but not through scraping, spidering, crawling, or other technology
            or software used to access data without the express written consent
            of Dreambuilders Membership or its users), view information and use
            the services that we provide on World Wide Group Web pages and in
            accordance with this Agreement. Any other use of the World Wide
            Group website and services contrary to our mission and purpose (such
            as seeking to connect to someone you do not know or trust, or to use
            information gathered from the World Wide Group website and
            DreamStream commercially unless expressly authorized by
            Dreambuilders Membership) is strictly prohibited and a violation of
            this Agreement. We reserve all rights not expressly granted in this
            Agreement, including, without limitation, title, ownership,
            intellectual property rights, and all other rights and interest in
            Dreambuilders Membership and all related items, including any and
            all copies made of the World Wide Group website.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.6.&nbsp;Our Rights and Obligations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.6.1.<i>&nbsp;Services Availability</i>:&nbsp;For as long as
            Dreambuilders Membership continues to offer the services,
            Dreambuilders Membership shall provide and seek to update, improve,
            and expand the services. As a result, we allow you to access the
            World Wide Group website and applications as they may exist and be
            available on any given day and have no other obligations, except as
            expressly stated in this Agreement. We may modify, replace, refuse
            access to, suspend, or discontinue services, partially or entirely,
            or change and modify prices for all or part of the services for you
            or for all our users in our sole discretion. All of these changes
            shall be effective upon their posting on our site or by direct
            communication to you unless otherwise noted. Dreambuilders
            Membership further reserves the right to withhold, remove, and or
            discard any content available as part of your account, with or
            without notice if deemed by Dreambuilders Membership to be contrary
            to this Agreement. For avoidance of doubt, Dreambuilders Membership
            has no obligation to store, maintain, or provide you a copy of any
            content that you or other users provide when using the services.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.6.2.<i>&nbsp;Disclosure of User Information</i>: You acknowledge,
            consent, and agree that we may access, preserve, and disclose your
            registration and any other information you provide if required to do
            so by law or in a good faith belief that such access preservation or
            disclosure is reasonably necessary in our opinion to: (1) comply
            with legal process, including, but not limited to, civil and
            criminal subpoenas, court orders, or other compulsory disclosures;
            (2) enforce this Agreement; (3) respond to claims of a violation of
            the rights of third parties, whether or not the third party is a
            user, individual, or government agency; (4) respond to customer
            service inquiries; or (5) protect the rights, property, or personal
            safety of Dreambuilders Membership, our users, or the public.
            Disclosures of user information to third parties other than those
            required to provide customer support, administer this agreement, or
            comply with legal requirements are addressed in the “Privacy”
            section.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.6.3.<i>&nbsp;Connections and Interactions With Other Users</i>:
            You are solely responsible for your interactions with other users.
            Dreambuilders Membership may, in certain circumstances, prohibit you
            from contacting other users through use of the services or otherwise
            limit your use of the services. Dreambuilders Membership reserves
            the right, but has no obligation, to monitor disputes between you
            and other members and to restrict, suspend, or close your account if
            Dreambuilders Membership determines, in our sole discretion, that
            doing so is necessary to enforce this Agreement. This privacy
            section applies to the entirety of the various uses granted to IBOs
            in these terms and conditions.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.7.<i>&nbsp;Disclaimer.</i>&nbsp;Do not rely on DreamStream, any
            information therein, or its continuation. We provide the platform
            for DreamStream and all information and services on an “as is” and
            “as available” basis. Dreambuilders Membership does not control or
            vet user-generated content for accuracy. We do not provide any
            express warranties or representations.&nbsp;To the fullest extent
            permissible under applicable law, we disclaim any and all implied
            warranties and representations, including, without limitation, any
            warranties of merchantability, fitness for a particular purpose,
            title, accuracy of data, and noninfringement. If you are
            dissatisfied or harmed by DreamStream or anything related to
            DreamStream, you may close your DreamStream account and terminate
            this Agreement in accordance with the “Termination” section and such
            termination shall be your sole and exclusive
            remedy.&nbsp;Dreambuilders Membership is not responsible, and makes
            no representations or warranties, for the delivery of any messages
            (such as private messaging or posting of answers or transmission of
            any other user-generated content) sent through DreamStream to
            anyone. In addition, we neither warrant nor represent that your use
            of the service will not infringe on the rights of third parties. Any
            material, service, or technology described or used on the website or
            application may be subject to intellectual property rights owned by
            third parties who have licensed such material, service, or
            technology to us.&nbsp;Dreambuilders Membership does not have any
            obligation to verify the identity of the persons subscribing to its
            services, nor does it have any obligation to monitor the use of its
            services by other users of DreamStream; therefore, Dreambuilders
            Membership disclaims all liability for identity theft or any other
            misuse of your identity or information.&nbsp;Dreambuilders
            Membership does not guarantee that the services it provides will
            function without interruption or errors in functioning. In
            particular, the operation of the services may be interrupted due to
            maintenance, updates, or system or network failures. Dreambuilders
            Membership disclaims all liability for damages caused by any such
            interruption or errors in functioning. Furthermore, Dreambuilders
            Membership disclaims all liability for any malfunctioning,
            impossibility of access, or poor use conditions of the World Wide
            Group site due to inappropriate equipment, disturbances related to
            internet service providers, to the saturation of the internet
            network, and for any other reason.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.8.<i>&nbsp;Limitation of Liability.</i>
            <b>&nbsp;</b>Some countries do not allow the limitation or exclusion
            of liability in contracts with consumers; as a result, the contents
            of this section may not apply to you.&nbsp;Neither Dreambuilders
            Membership nor any of our subsidiaries, affiliated companies,
            employees, shareholders, or directors shall be cumulatively liable
            for (a) any damages in excess of five times the most recent monthly
            fee that you paid for a premium service, if any, or USD $100,
            whichever amount is greater, or (b) any special, incidental,
            indirect, punitive, or consequential damages or loss of use, profit,
            revenue, or data to you or any third person arising from your use of
            the service, any platform applications or any of the content or
            other materials on, accessed through, or downloaded from the World
            Wide Group website and DreamStream. This limitation of liability is
            part of the basis of the bargain between the parties and without it
            the terms and prices charged would be different. This limitation of
            liability shall:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Apply regardless of whether (1) you base your claim on contract,
            tort, statute, or any other legal theory, (2) we knew or should have
            known about the possibility of such damages, or (3) the limited
            remedies provided in this section fail of their essential purpose;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Not apply to any damage that Dreambuilders Membership may cause you
            intentionally or knowingly in violation of this Agreement or
            applicable law, or as otherwise mandated by applicable law that
            cannot be disclaimed from in this Agreement; and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Not apply if you have entered into a separate agreement to purchase
            premium services with a separate Limitation of Liability provision
            that supersedes this section in relation to those premium services.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.9.&nbsp;Termination
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.9.1.<i>&nbsp;Mutual Rights of Termination</i>:&nbsp;You may
            terminate this Agreement, for any or no reason, at any time, with
            notice to Dreambuilders Membership. This notice will be effective
            upon Dreambuilders Membership processing your notice. For avoidance
            of doubt, only Dreambuilders Membership or the party paying for the
            services may terminate your access. Termination of your
            Dreambuilders Membership account includes disabling your access to
            the World Wide Group website and DreamStream and may also bar you
            from any future use of the Worldwide Group website and DreamStream.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.9.2.<i>&nbsp;Misuse of the Services</i>:&nbsp;Dreambuilders
            Membership may restrict, suspend, or terminate the account of any
            user who abuses or misuses the services. Misuse of the services
            includes abusing the DreamStream services, infringing any
            intellectual property rights, violating any of the applicable rules,
            or any other behavior that Dreambuilders Membership, in its sole
            discretion, deems contrary to its purpose. In addition, and without
            limiting the foregoing, Dreambuilders Membership has adopted a
            policy of terminating accounts of users who, in Dreambuilders
            Membership’s sole discretion, are deemed to be repeat infringers
            under the United States Copyright Act.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.10.&nbsp;Dispute Resolution&nbsp;(See Section 15 of “Agreement to
            Use” wwdb.com website). The Dispute Resolution procedures and terms
            contained in Section 15 of “Agreement to Use” wwdb.com are all
            incorporated in this section as if fully rewritten herein.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.25in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.11.DreamStream User “Dos” and “Don’ts”&nbsp;As a condition to
            access DreamStream, you agree to this User Agreement and to strictly
            observe the following “Dos” and “Don’ts.”
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.11.1.
            <i>
              &nbsp;<b>Do</b>&nbsp;undertake the following
            </i>
            :
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Comply with all applicable laws, including, without limitation,
            privacy laws, intellectual property laws, export control laws, tax
            laws, and regulatory requirements;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Provide accurate information to us and update it as necessary;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Review and comply with notices sent by Dreambuilders Membership
            concerning the services;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Use the services in a professional manner.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.75in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            ▪
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            18.11.2.
            <b>
              <i>&nbsp;Do not</i>
            </b>
            <i>&nbsp;undertake the following</i>:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Act dishonestly or unprofessionally by engaging in unprofessional
            behavior by posting inappropriate, inaccurate, or objectionable
            content to DreamStream.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Harass, abuse, or harm another person, including sending unwelcomed
            communications to others using DreamStream.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Upload a profile image that is not your likeness or a head-shot
            photo; or
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Upload, post, email, InMail, transmit, or otherwise make available
            or initiate any content that:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            1.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Falsely states, impersonates, or otherwise misrepresents your
            identity, including but not limited to the use of a pseudonym, or
            misrepresenting your current or previous positions and
            qualifications, or your affiliations with a person or entity, past
            or present;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            2.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Is unlawful, libelous, abusive, obscene, discriminatory, or
            otherwise objectionable;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Adds to a content field content that is not intended for such field
            (i.e., submitting a telephone number in the “title” or any other
            field, or including telephone numbers, email addresses, street
            addresses, or any personally identifiable information for which
            there is not a field provided by DreamStream);{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            4.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Includes information that you do not have the right to disclose or
            make available under any law or under contractual or fiduciary
            relationships (such as insider information, or proprietary and
            confidential information learned or disclosed as part of employment
            relationships or under nondisclosure agreements);{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Infringes upon patents, trademarks, trade secrets, copyrights, or
            other proprietary rights;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            6.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Includes any unsolicited or unauthorized advertising, promotional
            materials, “junk mail,” “spam,” “chain letters,” or any other form
            of solicitation. This prohibition includes but is not limited to
            using DreamStream to connect to people who don’t know you and then
            sending unsolicited promotional messages to those connections
            without their permission;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            7.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Contains software viruses, worms, or any other computer code, files,
            or programs that interrupt, destroy, or limit the functionality of
            any computer software or hardware or telecommunications equipment of
            Dreambuilders Membership or any user of the World Wide Group
            website; and/or{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "192.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Forges headers or otherwise manipulates identifiers in order to
            disguise the origin of any communication transmitted through the
            services.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Duplicate, license, sublicense, publish, broadcast, transmit,
            distribute, perform, display, sell, rebrand, or otherwise transfer
            information found on the World Wide Group website or DreamStream
            (excluding content posted by you) except as permitted in this
            Agreement;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Utilize or copy information, content, or any data you view on and/or
            obtain from the World Wide Group website or DreamStream to provide
            any service that is competitive, in Dreambuilders Membership’s sole
            discretion, with Dreambuilders Membership;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Adapt, modify, or create derivative works based on DreamStream or
            technology underlying the services, or other users’ content, in
            whole or part;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Rent, lease, loan, trade, sell/re-sell access to DreamStream or any
            information therein, or the equivalent, in whole or part;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Sell, sponsor, or otherwise monetize a DreamStream group or any
            other service or functionality of DreamStream, without the express
            written permission of Dreambuilders Membership;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Deep-link to the World Wide Group site for any purpose, (i.e.,
            including a link to a World Wide Group Web page) unless expressly
            authorized in writing by Dreambuilders Membership;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Remove any copyright, trademark, or other proprietary rights notices
            contained in or on the World Wide Group website or DreamStream,
            including those of both Dreambuilders Membership and any of its
            licensors;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Collect, use, copy, or transfer any information, including, but not
            limited to, personally identifiable information obtained from the
            World Wide Group website or DreamStream, except as expressly
            permitted in this Agreement or as the owner of such information may
            expressly permit;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Share information of non-users without their express consent;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Use manual or automated software, devices, scripts robots, other
            means, or processes to access, “scrape,” “crawl,” or “spider” any
            Web pages or other services contained in the site;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Use bots or other automated methods to access the World Wide Group
            website, add or download contacts, send or redirect messages, or
            perform other activities through the World Wide Group website,
            unless explicitly permitted by Dreambuilders Membership;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Access, via automated or manual means or processes, World Wide
            Group’s website for purposes of monitoring the website’s
            availability, performance, or functionality for any competitive
            purpose;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Engage in “framing,” “mirroring,” or otherwise simulating the
            appearance or function of the World Wide Group website;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Attempt to or actually access the World Wide Group website by any
            means other than through the interfaces provided by Dreambuilders
            Membership such as its mobile application or by navigating to{" "}
          </span>
          <span className="MsoHyperlink">
            <span style={{ fontFamily: '"Open Sans",serif' }}>
              www.wwdb.com
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            {" "}
            using a Web browser. This prohibition includes accessing or
            attempting to access the World Wide Group website using any
            third-party service, including software-as-a-service platforms that
            aggregate access to multiple services, including the World Wide
            Group website;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Attempt to or actually override any security component included in
            or underlying the World Wide Group website; and/or{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "156.0pt",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            •
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Engage in any action that directly or indirectly interferes with the
            proper working of or places an unreasonable load on the World Wide
            Group website’s infrastructure, including, but not limited to,
            sending unsolicited communications to other users, attempting to
            gain unauthorized access to the World Wide Group website, or
            transmitting or activating computer viruses through or on the World
            Wide Group website.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            19.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Modifications and Severability&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership reserves the right to modify from time to
            time and without prior notice, the terms and conditions affecting
            the use of this website, the level of services provided, prices
            charged, as well as the benefits associated with any level of
            purchased services. These Terms and Conditions were last revised on
            April 25, 2024. If you do not want to subscribe to be a Member and
            DreamStream user, do not conclude the Agreement. By subscribing, you
            acknowledge that you have read and understood the terms and
            conditions of this Agreement and that you agree to be bound by all
            of its provisions.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: ".25in",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            20.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Notice to Residents of the State of California
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            This section supplements the remainder of this Privacy Policy and
            (a) applies solely to residents of the State of California
            (“California Residents”) to the extent the California Consumer
            Privacy Act of 2018 (as amended by the California Privacy Rights
            Act, the “CCPA”) applies to our processing of the Personal
            Information of such California Residents, and (b) serves as the
            “Notice at Collection” required by the CCPA. When used in this
            Privacy Policy, the term “Personal Information” has the same meaning
            as the term “personal information” under the CCPA. Personal
            Information does not include aggregated or de-identified
            information, or any other information the CCPA excludes from its
            scope. Our statement above that “We will not sell your Personal
            Information” includes any actions that meet the definition of a
            “sale” of personal information under the CCPA with respect to
            California Residents. Likewise, we also will not “share” California
            Residents’ Personal Information, as that term is defined under the
            CCPA. Under the CCPA, and as further described below, each with
            respect to the immediately previous 12 months, California Residents
            have the right to request from a business with whom they have an
            established business relationship certain information with respect
            to the types and categories of personal information such business
            collects, the categories of sources from which such personal
            information is collected, the purpose for which such personal
            information is collected, the categories and identities of third
            parties with whom such business shares such personal information for
            direct marketing purposes by such third party, and the specific
            pieces of personal information collected.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>
            o
            <span style={{ font: '7.0pt "Open Sans"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            20.1.<i>Rights of California Residents Under the CCPA: </i>We will
            honor verified requests to exercise the rights the CCPA provides to
            California Residents regarding their personal information, and we
            will not discriminate against California Residents in any manner
            prohibited by applicable law for exercising such rights. Upon
            request, we will provide California Residents with information about
            our collection and use of their personal information over the last
            12 months, including categories and specific pieces of personal
            information we have collected about them, our business purpose(s)
            for collecting it, and the categories of third parties with whom we
            share it. California Residents may request that we delete any
            personal information we have collected about them. This right is not
            absolute, however, and under some circumstances we may decline to
            delete such personal information as permitted by the CCPA. We do not
            sell personal information to third parties in exchange for money.
            However, as set forth above, we share certain personal information
            with certain third parties, including Amway™ and IBOs. Under the
            CCPA, this exchange may be considered a “sale” or “sharing,” and as
            such, California Residents have the right to opt out of any such
            “sale” or “sharing” of personal information.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>
            o
            <span style={{ font: '7.0pt "Open Sans"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            20.2.
            <i>
              California Residents’ Exercise of Their Rights Under the CCPA:{" "}
            </i>
            California Residents can exercise their rights under the CCPA as
            described above by writing to us at World Wide Group, LLC, 717 S.
            Pines Rd, Spokane Valley, Washington 99206, USA, calling us at (509)
            924-0955, or emailing us at{" "}
          </span>
          <a href="mailto:support@wwdb.com">
            <span
              style={{
                fontFamily: '"Open Sans",serif',
                color: "#0563C1",
              }}
            >
              support@wwdb.com
            </span>
          </a>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            . Please provide sufficient detail to us in any such communication
            in order for us to properly assess and respond. We may be unable to
            respond to incomplete or vague requests, and we reserve the right to
            not respond to requests generated through third-party applications
            or automated processes without direct validation of such requests by
            individual California Residents.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>
            o
            <span style={{ font: '7.0pt "Open Sans"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            20.3.
            <i>
              Identity Verification Regarding Requests for Personal Information
              Under the CCPA:{" "}
            </i>
            We will require a California Resident to verify their identity
            before proceeding with a request as described above. Furthermore,
            any requests made by an individual acting on a California Resident’s
            behalf will require verification of the subject California Resident,
            the individual acting on such California Resident’s behalf, and a
            valid power of attorney under which the subject California Resident
            granted agency status to the representative making the
            request.&nbsp; Any such verification may require the subject
            California Resident or their representative to provide one or more
            forms of valid government identification.
          </span>
        </p>
        <h1
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "11.3pt",
            marginLeft: "120.1pt",
            lineHeight: "120%",
          }}
        >
          <b>
            <span
              style={{
                fontSize: "13.5pt",
                lineHeight: "120%",
                fontFamily: '"Open Sans",serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </h1>
        <h1
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "11.3pt",

            lineHeight: "120%",
          }}
        >
          <b>
            <span className={styles.subHeader}>
              Terms &amp; Conditions of Digital Delivery{" "}
            </span>
          </b>
        </h1>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "10.7pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "124%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            1.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "124%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Definitions<i> </i>&nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: ".5in",
            lineHeight: "125%",
          }}
        >
          <i>
            <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
              Publications,
            </span>
          </i>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            {" "}
            a division of Dreambuilders Membership, LLC, is the provider of the
            Business Support Materials Digital Delivery Service (the “Service”)
            that permits you to purchase the Product, such as audio recordings
            and videos, under certain terms and conditions as set forth in this
            Agreement.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            2.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            System Requirements&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "13.6pt",
            marginLeft: ".5in",
            textIndent: "-.7pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Use of the Service requires one or more compatible devices, Internet
            access (fees may apply), and certain software (fees may apply), and
            may require obtaining updates or upgrades from time to time. Because
            use of the Service involves hardware, software, and Internet access,
            your ability to use the Service may be affected by the performance
            of these factors. High-speed Internet access is strongly
            recommended. You acknowledge and agree that such system
            requirements, which may be changed from time to time, are your
            responsibility. The Service is not part of any other product or
            offering, and no purchase or obtaining of any other product shall be
            construed to represent or guarantee you access to the Service.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Policies and Usage Rules&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.1.&nbsp;Your use of the Products is conditioned upon your prior
            acceptance of the terms of this Agreement.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.2.&nbsp;You shall be authorized to use the Products only for
            personal, noncommercial use.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.3.&nbsp;Any exporting capabilities are solely an accommodation to
            you and shall not constitute a grant or waiver (or other limitation
            or implication) of any rights of the copyright owners in any audio
            or video content, sound recording, or artwork embodied in any
            Product.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.4. You agree that you will not attempt to, or encourage or assist
            any other person to, circumvent or modify any security technology or
            software that is part of the Service or used to administer the Usage
            Rules.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.5. The delivery of Products does not transfer to you any
            commercial or promotional use rights in the Products.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "104%",
              fontFamily: '"Courier New"',
              color: "black",
            }}
          >
            o
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            3.6.&nbsp;General compliance with laws. The Service is controlled
            and operated by Dreambuilders Membership, LLC, from its offices in
            the United States. You agree to comply with all local, state,
            federal, and national laws, statutes, ordinances, and regulations
            that apply to your use of the Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            4.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Privacy and Security&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.35pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            As a registered user of the Service, you may receive or establish an
            account (“Account”). You are solely responsible for maintaining the
            confidentiality and security of your Account. You should not reveal
            your Account information to anyone else or use anyone else’s
            Account. You are entirely responsible for all activities that occur
            on or through your Account, and you agree to immediately notify
            Dreambuilders Membership, LLC, of any unauthorized use of your
            Account or any other breach of security. Dreambuilders Membership,
            LLC, shall not be responsible for any losses arising out of the
            unauthorized use of your Account.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You understand that the Service, and products transacted through the
            Service, such as audio recordings and videos, may include a security
            framework using technology that protects digital information and
            limits your usage of Products to certain usage rules established by
            Dreambuilders Membership, LLC, and its licensors (“Usage Rules”).
            You agree to comply with such Usage Rules, as further outlined
            below, and you agree not to violate or attempt to violate any
            security components. You agree not to attempt to, or assist another
            person to, circumvent, reverse-engineer, decompile, disassemble, or
            otherwise tamper with any of the security components related to such
            Usage Rules for any reason whatsoever. Usage Rules may be controlled
            and monitored by Dreambuilders Membership, LLC, for compliance
            purposes, and Dreambuilders Membership, LLC, reserves the right to
            enforce the Usage Rules with or without notice to you. You will not
            access the Service by any means other than through software that is
            provided by Dreambuilders Membership, LLC, for accessing the
            Service. You shall not access or attempt to access an Account that
            you are not authorized to access. You agree not to modify the
            software in any manner or form, or to use modified versions of the
            software, for any purposes including obtaining unauthorized access
            to the Service. Violations of system or network security may result
            in civil or criminal liability. This privacy section applies to the
            entirety of the various uses granted to IBOs in these terms and
            conditions.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Payment&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You agree to provide accurate, current, and complete information
            required to register with the Service and at other points as may be
            required in the course of using the Service. You further agree to
            maintain and update your data as required to keep it accurate,
            current, and complete. Dreambuilders Membership, LLC, may terminate
            your rights to any or all of the Service if any information you
            provide is false, inaccurate, or incomplete. You agree that
            Dreambuilders Membership, LLC, may store and use the Registration
            Data you provide (including credit card information) for use in
            maintaining your accounts and billing fees to your credit card
            account.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: "Symbol", color: "black" }}>
            o
            <span style={{ font: '7.0pt "Open Sans"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.1.
            <i>First Contract Year: </i>Pursuant to the Enhanced Amway Promise
            Program (“EAP”), and as more specifically set forth in the Quality
            Assurance Standards Program Standards, A World Wide
            Dreambuilders–affiliated Amway™-powered IBO represents and
            understands that there is no cost to become an IBO. In addition, IBO
            represents and understands that for IBOs in their first Amway
            contract year, as defined in the Amway Registration and Renewal
            Agreements, Approved Providers, such as World Wide Group, LLC, are
            not permitted to charge first contract year IBOs for any Business
            Support Materials (BSM). First contract year IBOs are not permitted
            to purchase BSM from an Approved Provider or an IBO. To the extent
            World Wide Group, LLC provides BSM to a first contract year IBO,
            those BSM will be complimentary. IBOs in their first contract year
            cannot be charged, directly or indirectly, for any BSM.&nbsp;&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.2 <i>Payment for Products: </i>Access to this service is provided
            based on a monthly subscription fee to be billed on the first of
            each month. You agree that Dreambuilders Membership, LLC, may charge
            your credit card account for your subscription fee, and for any
            additional amounts (including extra royalty fees for certain items)
            as may be accrued by or in connection with your Account. You are
            responsible for the timely payment of all fees and for providing
            Dreambuilders Membership, LLC, with a valid credit card account for
            payment of all fees. All fees will be billed to the credit card you
            designate during the registration process. Your credit card will be
            charged the first business day of each month. If you want to
            designate a different credit card or if there is a change in your
            credit card status, you must change your credit card account
            information online in your wwdb.com Settings. (There may be a
            temporary disruption of your access to the Service until
            Dreambuilders Membership, LLC, can verify the validity of the new
            credit card account information.)&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.3. <i>Right to Change Prices and Availability of Products: </i>
            Prices and availability of any Products are subject to change at any
            time.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".2pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "99%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "99%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.4. <i>Electronic Signatures and Contracts: </i>Your use of the
            Service includes the ability to enter into agreements and/or to make
            transactions electronically. You acknowledge that your electronic
            submissions constitute your agreement and intent to be bound by and
            to pay for such agreements and transactions. Your agreement and
            intent to be bound by electronic submissions applies to all records
            relating to all transactions you enter on this site, including
            notices of cancellation, policies, contracts, and
            applications.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.5. In order to access and retain your electronic records, you may
            be required to have certain hardware and software, which are your
            sole responsibility.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "1.55pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            5.6. Charges to your credit card will show up on your statement as
            billed to “Diamond Publications.”
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "1.55pt",
            marginLeft: ".5in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            6.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Automatic Delivery&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.45pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You are signing up for Digital Delivery, and all content will be
            made available on Fridays at 3:00 p.m. (PST). It is your
            responsibility to download the audio files. Your credit card will be
            automatically charged for access to the Digital Delivery files on
            the first of each month. A World Wide Dreambuilders–affiliated
            Amway™-powered IBO in their first contract year cannot be charged,
            directly or indirectly, for any BSM.&nbsp;&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            7.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Refunds&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Business Support Materials are not covered under the Amway™
            Satisfaction Guarantee or its Buy-Back Rule. However, the IBO Rules
            of Conduct and this Agreement require{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.2pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership, LLC, to, upon request, buy back any
            Business Support Materials purchased for the buyer’s personal use,
            on commercially reasonable terms.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "16.2pt",
            marginLeft: "35.25pt",
            lineHeight: "normal",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You must directly contact Dreambuilders Membership, LLC, via Support
            to receive your refund.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Log into the Dreambuilders Membership, LLC website at wwdb.com and
            click on the tab in the main menu. A Support representative will
            help you process your refund. If you send an email, there is no
            guarantee that your refund will be processed.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.35pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            With this Agreement, you are signed up for
            <i>Digital Delivery</i>. All new Digital Delivery items will be made
            available for download on a set schedule, and your credit card will
            be charged your monthly subscription fee accordingly.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Intellectual Property&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.1. <i>Acknowledgement of Ownership: </i>You agree that the
            Service, including but not limited to products, graphics, audio
            clips, video clips, and editorial content, contains proprietary
            information and material that is owned by Dreambuilders Membership,
            LLC, and/or its licensors, and is protected by applicable
            intellectual property and other laws, including but not limited to
            copyright, and that you will not use such proprietary information or
            materials in any way whatsoever except for use of the Service in
            compliance with the terms of this Agreement. No portion of the
            Service may be reproduced in any form or by any means, except as
            expressly permitted within the terms of this Agreement. You agree
            not to modify, rent, lease, loan, sell, distribute, or create
            derivative works based on the Service, in any manner, and you shall
            not exploit the Service in any unauthorized way whatsoever,
            including but not limited to, by trespass or burdening network
            capacity.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".2pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "99%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "99%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.2. <i>Copyrights:</i>
            The use of the software or any part of the service, except for use
            of the service as permitted in these Terms of Service, is strictly
            prohibited and infringes on the intellectual property rights of
            others and may subject you to civil and criminal penalties,
            including possible monetary damages, for copyright
            infringement.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            8.3. <i>Trademarks:</i>
            Publications, Dreambuilders Membership, LLC, and the logos used in
            connection with the Service are trademarks or registered trademarks
            of Dreambuilders Membership, LLC, in the United States and/or other
            countries. Other trademarks, service marks, graphics, and logos used
            in connection with the Service may be the trademarks of their
            respective owners. You are granted no right or license with respect
            to any of the aforesaid trademarks and any use of such
            trademarks.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            9.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Termination&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            9.1. <i>Termination by Dreambuilders Membership, LLC: </i>If you
            fail, or Dreambuilders Membership, LLC, suspects that you have
            failed, to comply with any of the provisions of this Agreement,
            including but not limited to failure to make payment of fees due,
            failure to provide Dreambuilders Membership, LLC, with a valid
            credit card or with accurate and complete Registration Data, failure
            to safeguard your Account information, violation of the Usage Rules
            or any license to the software, or infringement or other violation
            of third parties’ rights, Dreambuilders Membership, LLC, at its sole
            discretion, without notice to you may: (i) terminate this Agreement
            and/or your Account, and you will remain liable for all amounts due
            under your Account up to and including the date of termination;
            and/or (ii) terminate the license to the software; and/or (iii)
            preclude access to the Service (or any part thereof).&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            9.2. <i>Termination of the Service: </i>Dreambuilders Membership,
            LLC, reserves the right to modify, suspend, or discontinue the
            Service (or any part or content thereof) at any time with or without
            notice to you, and Dreambuilders Membership, LLC, will not be liable
            to you or to any third party should it exercise such rights.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Disclaimers and Liability Limitations&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.1. Dreambuilders Membership, LLC, does not guarantee, represent,
            or warrant that your use of the service will be uninterrupted or
            error-free, and you agree that from time to time Dreambuilders
            Membership, LLC, may remove the service for indefinite periods of
            time, or cancel the service at any time, without notice to you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.2. You expressly agree that your use of, or inability to use, the
            service is at your sole risk. The service and all products and
            services delivered to you through the service are (except as
            expressly stated by Dreambuilders Membership, LLC) provided “as is”
            and “is available’ for your use, without warranties of any kind,
            either expressed or implied, including all implied warranties of
            merchantability, fitness for a particular purpose, title, and
            noninfringement. Because some jurisdictions do not allow the
            exclusion of implied warranties, the above exclusion of implied
            warranties may not apply to you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.3. In no case shall Dreambuilders Membership, LLC, its directors,
            officers, employees, affiliates, agents, contractors, or licensors
            be liable for any direct, indirect, incidental, punitive, special,
            or consequential damages arising from your use of any of the
            services or for any other claim related in any way to your use of
            the services, including, but not limited to, any errors or omissions
            in any content, or any loss or damage of any kind incurred as a
            result of the use of any content (or product) posted, transmitted,
            or otherwise made available via the service, even if advised of
            their possibility. Because some states or jurisdictions do not allow
            the{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: "5.05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            exclusion or the limitation of liability for consequential or
            incidental damages, in such states or jurisdictions, Dreambuilders
            Membership, LLC’s liability shall be limited to the extent permitted
            by law.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.4. Dreambuilders Membership, LLC, shall use reasonable efforts to
            protect information submitted by you in connection with the
            services, but you acknowledge and agree that your submission of such
            information is at your sole risk, and Dreambuilders Membership, LLC,
            hereby disclaims any and all liability to you for any loss or
            liability relating to such information in any way.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.5. Dreambuilders Membership, LLC, does not represent or guarantee
            that the service will be free from loss, corruption, attack,
            viruses, interference, hacking, or other security intrusion, and
            Dreambuilders Membership, LLC, disclaims any liability relating
            thereto. Products can only be downloaded once; after being
            downloaded, they cannot be replaced if lost for any reason. You
            shall be responsible for backing up your own system, including any
            products purchased or rented from the iTunes Store or other such
            platform that are stored on your system.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.6. There are no warranties expressed, implied, or offered by
            Dreambuilders Membership, LLC. As provided in the Amway™ Rules of
            Conduct, there is no guarantee as to the results of the services or
            goods purchased or for the techniques, approaches, or attitudes
            suggested in Business Support Materials. You must never make any
            such claim of a guarantee in the reselling of Business Support
            Materials. Your success depends on your own hard work.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.7. Goods sold by Dreambuilders Membership, to the fullest extent
            permissible by applicable law, are sold with a disclaimer as to all
            implied warranties, including but not limited to, implied warranties
            of merchant ability and fitness for a particular purpose.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: "1.0in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Courier New"', color: "black" }}>o</span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            10.8. Certain state laws do not allow limitations on implied
            warranties or the exclusion or limitation of certain damages. If
            those state laws apply to you, some or all of the above disclaimers,
            exclusions, or limitations may not apply, and you may have
            additional rights.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.15pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            11.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Waivers and Indemnity&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            You agree to indemnify and hold harmless Diamond Publications,
            Dreambuilders Membership, LLC, its parent company, World Wide Group,
            LLC, and all its affiliates, its directors, officers, employees,
            agents, contractors, or licensors from any claims arising out of
            your breach of this Agreement. You further agree to waive any claim
            for any action taken by Dreambuilders Membership, LLC, as part of
            its investigation to determine if a breach of this agreement has
            occurred. This waiver means that you cannot sue or recover any
            damages from Dreambuilders Membership, LLC, its directors, officers,
            employees, affiliates, agents, contractors, or licensors as a result
            of its decision to remove or refuse to process any information or
            content, to warn you, to suspend or terminate your access to
            services, or to take any other action during the investigation of a
            suspected violation or as a result of Dreambuilders Membership,
            LLC’s conclusion that a breach of this Agreement has occurred. This
            waiver and indemnity provision applies to any breach of this
            Agreement.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            12.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Applicable Law and Jurisdiction&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: ".25pt",
            marginLeft: ".5pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.6pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            The Terms and Conditions of the use of this website, including the
            purchase of any goods or services, shall be controlled by the law of
            the state of Michigan.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "15.5pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            13.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dispute Resolution{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "15.5pt",
            marginLeft: "31.5pt",
            textIndent: "-4.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            &nbsp; (See Section 15 of “Agreement to Use” wwdb.com website, the
            terms of which are incorporated as though fully rewritten herein.)
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            14.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Changes to This Agreement&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.5pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership, LLC, reserves the right, at any time and
            from time to time, to update, revise, supplement, and otherwise
            modify this Agreement and to impose new or additional rules,
            policies, terms, or conditions on your use of the Service. Such
            updates, revisions, supplements, modifications, and additional
            rules, policies, terms, and conditions (collectively referred to in
            this Agreement as “Additional Terms”) will be effective immediately
            and incorporated into this Agreement. Your continued use of the
            Digital Downloads following will be deemed to constitute your
            acceptance of any and all such Additional Terms. All Additional
            Terms are hereby incorporated into this Agreement by this
            reference.&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "13.3pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            15.
          </span>
          <span
            style={{
              fontSize: "7.0pt",
              lineHeight: "104%",
              fontFamily: '"Open Sans",serif',
              color: "black",
            }}
          >
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Modifications and Severability&nbsp;{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0in",
            marginRight: ".05pt",
            marginBottom: "19.0pt",
            marginLeft: "35.75pt",
            textIndent: "-.5pt",
            lineHeight: "104%",
          }}
        >
          <span style={{ fontFamily: '"Open Sans",serif', color: "black" }}>
            Dreambuilders Membership reserves the right to modify from time to
            time and without prior notice, the terms and conditions affecting
            the use of this website, the level of services provided, prices
            charged, as well as the benefits associated with any level of
            purchased services. These Terms and Conditions were last revised on
            August 15, 2024.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "13.5pt",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className={styles.header}>
            Do you agree to these Terms and Conditions?
          </div>
        </p>
      </div>
    </div>
  );
}
