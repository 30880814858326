import styles from "./index.module.css";
import PropTypes from "prop-types";

/**
 * Sub header used in personal information steps
 */

export default function PersonalInfoSubheader({ header, info }) {
  return (
    <>
      <div className={styles.header}>{header}</div>

      {info.map((i) => (
        <div key={i} className={styles.info}>{i}</div>
      ))}
    </>
  );
}

PersonalInfoSubheader.propTypes = {
  header: PropTypes.string,
  info: PropTypes.array,
};

