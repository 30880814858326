import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import { Ok, IkonateContext } from "react-ikonate";
import PropTypes from "prop-types";

/**
 * Custom button used when selecting membership choice.
 */

export function CheckBox({
  title,
  onClick,
  className,
  selected,
}) {
  return (
    <>
      {selected ? (
        <button
          className={classNames([
            styles.button2,
            className,
          ])}
          onClick={onClick}
        >
          <IkonateContext.Provider
            value={{ border: 5, size: "27px", style: "round" }}
          >
            <Ok />
          </IkonateContext.Provider>
        </button>
      ) : (
        <button
          className={classNames([
            styles.button,
            className,
          ])}
          onClick={onClick}
        >
          {title || "Button"}
        </button>
      )}
    </>
  );
}

CheckBox.propTypes = {
  title: PropTypes.string, // Displayed in button when selected is false
  onClick: PropTypes.func, // Function passed in to change button style
  className: PropTypes.string,
  selected: PropTypes.bool, // Used to determine what button style is displayed
};
