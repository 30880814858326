import classNames from "classnames";
import styles from "./index.module.css";
import { ArrowLeft, ArrowRight, IkonateContext } from "react-ikonate";
import PropTypes from "prop-types";

/**
 * Rounded button, you can display an arrow by passing in "left" or "right" as the arrow prop.
 */

export default function NextButton({ onClick, title, arrow, ...props }) {
  return (
    <button onClick={onClick} {...props} className={classNames(styles.button, props.className)}>
      {title ? <div className={styles.title}>{title}</div> : ""}
      <div className={styles.arrow}>
        <IkonateContext.Provider value={{ border: 2, size: "20px" }}>
          {arrow === "left" ? (
            <ArrowLeft />
          ) : arrow === "right" ? (
            <ArrowRight />
          ) : (
            ""
          )}
        </IkonateContext.Provider>
      </div>
    </button>
  );
}


NextButton.propTypes = {
  arrow: PropTypes.string, // displayed arrow inside of button (optional)
  title: PropTypes.string,
  onClick: PropTypes.func,
};

