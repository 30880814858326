import { useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import Sort from "../../Images/sort-solid.svg";
import PropTypes from "prop-types";

/**
 * Custom select box
 */

function useOutsideAlerter(ref, clickOutFn) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        clickOutFn(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default function SelectBox({
  placeHolder,
  setValue,
  options,
  value,
  displayField = null,
  valueField = null,
  tabIndex = 0,
}) {
  const [currentValue, setCurrentValue] = useState(placeHolder);
  const [openDropdown, setOpenDropdown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpenDropdown);

  const onChange = (display, value) => {
    setCurrentValue(display);
    setValue(value);
    setOpenDropdown(false);
  };

  return (
    <>
      <div
        className={styles.dropdownContainer}
        ref={wrapperRef}
        tabIndex={tabIndex}
      >
        <div
          className={classNames(styles.inputBox, styles.noLabel)}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {placeHolder}
        </div>
        {openDropdown ? (
          <div className={styles.dropdownOptionsContainer}>
            {/* <div
              className={styles.dropdownOptions}
              onClick={() => onChange(placeHolder)}
            >
              - Select Country* -
            </div> */}

            {options
              .filter((option) => option[displayField] !== placeHolder)
              .map((option) => {
                return (
                  <div
                    tabIndex={0}
                    key={option[displayField]}
                    className={styles.dropdownOptions}
                    onClick={() =>
                      onChange(
                        displayField ? option[displayField] : option,
                        valueField ? option[valueField] : option
                      )
                    }
                  >
                    {displayField ? option[displayField] : option}
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
      {/* <select
        id={placeHolder}
        name={placeHolder}
        value={currentValue}
        onChange={(e) => onChange(e.target.value)}
        className={classNames(styles.inputBox, styles.noLabel)}
      >
        <option value={placeHolder}>{placeHolder}</option>
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={valueField ? option[valueField] : option}
            >
              {displayField ? option[displayField] : option}
            </option>
          );
        })}
      </select> */}
    </>
  );
}

SelectBox.propTypes = {
  placeHolder: PropTypes.string,
  setValue: PropTypes.func, // Sets the value of selected option
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]), // list of options to pass in
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
