import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import styles from "./index.module.css";
import WelcomeHeader from "components/WelcomeHeader";
import { Button } from "components/Button";
import Receipt from "components/Receipt";

import AllSetImg from "../../Images/You're_all_set.svg";
import MobileApp from "../../Images/WWG_Mobile_App_Icon.svg";
import AppStore from "../../Images/App_Store_Button.svg";
import Google from "../../Images/Google_Play_Button.svg";
import DreamStream from "../../Images/DreamStream_Logo.svg";
import Kate from "../../Images/Communikate_Logo.svg";
import { SignUpContainer } from "hooks/useSignUp";
import { useParams } from "react-router-dom";
import environment from "shared/environment";
import mobile from "mobileBridge";

/**
 * Final page in app with receipt and links to app store
 */

export default function AllSet() {
  const { receiptId, ibo: iboNum } = useParams();
  const { loadReceipt, setShowLoadingIcon } = SignUpContainer.useContainer();
  const [receipt, setReceipt] = useState(null);

  useEffect(() => {
    if (receiptId && iboNum) {
      setShowLoadingIcon(true);
      loadReceipt.mutateAsync({ receiptId, iboNum }).then((data) => {
        setShowLoadingIcon(false);
        setReceipt(data);
      });
    }
  }, [receiptId, iboNum]);

  return (
    <>
      {!receipt && <div>Loading receipt...</div>}
      {receipt && (
        <div className={styles.container}>
          {!window.location.toString().includes("receipt") && (
            <div className={styles.noPrint}>
              <WelcomeHeader
                img={AllSetImg}
                info={
                  "Thank you for signing up for your free trial with Dreambuilders™ Membership! We know you’re going to find so much value in this, both personally and professionally. Take a moment to review your receipt and take the next steps in building your life by downloading the WWG™ mobile app, signing up for DreamStream™ and CommuniKate™ below."
                }
              />
              <div className={styles.imgCenter}>
                <img src={MobileApp} alt="Mobile App" />
              </div>

              <div className={styles.imgInfo}>
                <div>Download the WWG™ Mobile App!</div>
              </div>
              <div className={styles.download}>
                <img
                  className={styles.appStore}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.WWG_IOS_URL)
                      : window.open(environment.WWG_IOS_URL, "_blank");
                  }}
                  src={AppStore}
                  alt="App Store"
                />
                <img
                  src={Google}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.WWG_ANDROID_URL)
                      : window.open(environment.WWG_ANDROID_URL, "_blank");
                  }}
                  alt="Google Play"
                />
              </div>
              <div className={styles.borderBottom}></div>
              <div className={styles.imgCenter}>
                <img src={DreamStream} alt="Mobile App" />
              </div>
              <div className={styles.imgInfo}>
                <div>
                  Your DreamStream™ account is ready and waiting! Download the
                  DreamStream™ App!
                </div>
              </div>
              <div className={styles.download}>
                <img
                  className={styles.appStore}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.DREAMSTREAM_IOS_URL)
                      : window.open(environment.DREAMSTREAM_IOS_URL, "_blank");
                  }}
                  src={AppStore}
                  alt="App Store"
                />
                <img
                  src={Google}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.DREAMSTREAM_ANDROID_URL)
                      : window.open(
                          environment.DREAMSTREAM_ANDROID_URL,
                          "_blank"
                        );
                  }}
                  alt="Google Play"
                />
              </div>
              <div className={styles.imgInfo1}>
                <div>
                  Be sure to use the email address you used to sign up with
                  Dreambuilders Membership when you sign in.
                </div>
              </div>
              <div className={styles.imgInfo}>
                <Button
                  title={"Set Up My Account"}
                  arrow={"none"}
                  className={styles.backgroundOne}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.DREAMSTREAM_WEB_URL)
                      : window.open(environment.DREAMSTREAM_WEB_URL, "_blank");
                  }}
                />
              </div>
              <div className={styles.borderBottom}></div>
              <div className={styles.imgCenter}>
                <img src={Kate} alt="Mobile App" />
              </div>
              <div className={styles.imgInfo}>
                <div>
                  Communikate is an affiliate partner who's service helps unify
                  your business communications.
                </div>
              </div>
              <div className={styles.imgInfo}>
                <Button
                  title={"Sign Up"}
                  arrow={"none"}
                  className={styles.backgroundTwo}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.COMMUNIKATE_WEB_URL)
                      : window.open(environment.COMMUNIKATE_WEB_URL, "_blank");
                  }}
                />
              </div>
              <div className={styles.imgInfo}>
                <div>Download the Communikate Mobile App!</div>
              </div>
              <div className={styles.download}>
                <img
                  className={styles.appStore}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.COMMUNIKATE_IOS_URL)
                      : window.open(environment.COMMUNIKATE_IOS_URL, "_blank");
                  }}
                  src={AppStore}
                  alt="App Store"
                />
                <img
                  src={Google}
                  onClick={() => {
                    mobile.isApp()
                      ? mobile.openLink(environment.COMMUNIKATE_ANDROID_URL)
                      : window.open(
                          environment.COMMUNIKATE_ANDROID_URL,
                          "_blank"
                        );
                  }}
                  alt="Google Play"
                />
              </div>
            </div>
          )}

          <Receipt
            iboNumber={iboNum}
            name={receipt.Name}
            confirmation={receipt.Confirmation}
            orderDate={receipt.ProcessedOn}
            renewalDate={receipt.RenewalOn}
            total={receipt.Total}
            items={receipt.Items}
            payment={receipt.Payment}
            billingAddress={receipt.BillingAddress}
          />
        </div>
      )}
    </>
  );
}

AllSet.propTypes = {
  iboNum: PropTypes.string,
};
