import { SignUpContainer } from "hooks/useSignUp";
import classnames from "classnames";
import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import PersonalInfoSubheader from "components/PersonalInfoSubheader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import PropTypes from "prop-types";

/**
 * Step for user to choose login information (username and password)
 */

export default function LoginInformation({
  userName,
  setUsername,
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation,
  onBack,
  onClick,
  iboNum,
}) {
  const { error } = SignUpContainer.useContainer();

  const pageError = (error && error.LoginInformation) || {};
  const topError =
    pageError.userName ||
    pageError.password ||
    pageError.passwordConfirmation ||
    pageError.api;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Login Information"}
          />
        </div>
        <div className={styles.header}>
          <PersonalInfoSubheader
            header={"Create Your Profile"}
            info={[
              "The following information will be used to log into the WWG™ mobile app and wwdb.com. Please make sure you save this information for future use in a secure place.",
            ]}
          />
        </div>

        {topError && <div className={styles.errorText}>{topError}</div>}
        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.userName,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Username"}
            setValue={setUsername}
            value={userName}
            maxlength="50"
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.password,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Password"}
            setValue={setPassword}
            type="password"
            value={password}
            maxlength="50"
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]:
              !!pageError.password || !!pageError.passwordConfirmation,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Confirm Password"}
            setValue={setPasswordConfirmation}
            type="password"
            value={passwordConfirmation}
            maxlength="50"
          />
        </div>
        <div className={styles.rules}>
          <div>
            Password must contain one of the following:
          </div>
          <ul>
            <li>Eight (8) or more characters</li>
            <li>One (1) letter (abc)</li>
            <li>One (1) number (0-9)</li>
            <li>One (1) symbol (!@#$%^&*:;)</li>
          </ul>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <NextButton title={"Next"} arrow={"right"} onClick={onClick} />
          </div>
        </div>
      </div>
    </>
  );
}

LoginInformation.propTypes = {
  userName: PropTypes.string,
  setUsername: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  passwordConfirmation: PropTypes.string,
  setPasswordConfirmation: PropTypes.func,
  onBack: PropTypes.func,
  onClick: PropTypes.func,
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
