import { SignUpContainer } from "hooks/useSignUp";
import classnames from "classnames";
import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import PersonalInfoSubheader from "components/PersonalInfoSubheader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import SelectBox from "components/SelectBox";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

/**
 * Step for user to enter address information
 */

export default function Address({
  country,
  setCountry,
  street,
  setStreet,
  apt,
  setApt,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  onClick,
  onBack,
  iboNum,
}) {
  const { error } = SignUpContainer.useContainer();
  const { countries } = SignUpContainer.useContainer();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryIsChanged, setCountryIsChanged] = useState(false);
  const [stateSelected, setStateSelected] = useState(false);

  useEffect(() => {
    if (countries.length > 0 && country) {
      let selected = countries.find(
        (a) =>
          a.name.toLowerCase() == country.toLowerCase() ||
          a.abbreviation.toLowerCase() == country.toLowerCase()
      );
      setSelectedCountry(selected);
    }
  }, [country, countries]);

  const setCountryInternal = (value) => {
    setCountryIsChanged(true);
    let selected = countries.find(
      (a) =>
        a.name.toLowerCase() == value.toLowerCase() ||
        a.abbreviation.toLowerCase() == value.toLowerCase()
    );
    if (selected) {
      setSelectedCountry(selected);
      setCountry(value);
      setStateSelected(false);
    } else {
      setSelectedCountry(null);
      setCountry(null);
    }
  };

  const pageError = (error && error.Address) || {};
  const topError =
    pageError.country ||
    pageError.street ||
    pageError.city ||
    pageError.state ||
    pageError.zip ||
    pageError.api;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Personal Information"}
          />
        </div>
        <div className={styles.header}>
          <PersonalInfoSubheader
            header={"Address"}
            info={[
              "Please enter the information you'd like us to use as your billing address.",
            ]}
          />
        </div>

        {topError && <div className={styles.errorText}>{topError}</div>}
        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.country,
          })}
        >
          <SelectBox
            placeHolder={selectedCountry ? selectedCountry.name : "Country*"}
            setValue={setCountryInternal}
            options={countries}
            value={country}
            displayField={"name"}
            valueField={"abbreviation"}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.street,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Street and number, P.O. Box*"}
            setValue={setStreet}
            value={street}
            maxlength={200}
          />
        </div>

        <div className={styles.input}>
          {" "}
          <InputBox
            placeHolder={"Apartment, suite, floor, etc."}
            setValue={setApt}
            value={apt}
            maxlength={200}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.city,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"City*"}
            setValue={setCity}
            value={city}
            maxlength={50}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.state,
          })}
          onClick={() => setCountryIsChanged(false)}
        >
          <SelectBox
            placeHolder={
              selectedCountry &&
              selectedCountry.states &&
              selectedCountry.states.length > 0
                ? stateSelected || state
                  ? selectedCountry.states.find(
                      (st) => st.abbreviation === state
                    ).name
                    ? selectedCountry.states.find(
                        (st) => st.abbreviation === state
                      ).name
                    : "- Select State* -"
                  : "- Select State* -"
                : "Please select a country"
            }
            setValue={(e) => {
              setStateSelected(true);
              setState(e);
            }}
            options={selectedCountry ? selectedCountry.states : []}
            value={state}
            displayField={"name"}
            valueField={"abbreviation"}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.zip,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Zip Code*"}
            setValue={setZip}
            value={zip}
            maxlength={10}
          />
        </div>

        <div className={styles.required}>*Required</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <NextButton title={"Next"} arrow={"right"} onClick={onClick} />
          </div>
        </div>
      </div>
    </>
  );
}

Address.propTypes = {
  country: PropTypes.string,
  setCountry: PropTypes.func,
  street: PropTypes.string,
  setStreet: PropTypes.func,
  apt: PropTypes.string,
  setApt: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  state: PropTypes.string,
  setState: PropTypes.func,
  zip: PropTypes.string,
  setZip: PropTypes.func,
  onClick: PropTypes.func,
  onBack: PropTypes.func,
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
