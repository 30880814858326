import styles from "./index.module.css";
import NextButton from "components/NextButton";
import { useNavigate } from "react-router-dom";
import { SignUpContainer } from "hooks/useSignUp";
import { useEffect, useState } from "react";
import environment from "shared/environment";
import mobile from "mobileBridge";

/**
 * Welcome page displaying membership prices. Once get started is clicked the user is taken to the personal info section.
 */

export default function Code() {
  const { code, codeStatus, fetchCode, setShowLoadingIcon } =
    SignUpContainer.useContainer();
  const navigate = useNavigate();

  const [showToast, setShowToast] = useState(false);
  const [authErr, setAuthErr] = useState(false);
  const [_code, setCode] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    handleGetCode();
  }, []);

  useEffect(() => {
    code && setCode(code);
  }, [code]);

  useEffect(() => {
    if (_code && _code.inviteCode) {
      setAuthErr(false);
      setUrl(`https://signup${
        window.location.href.toLowerCase().includes("dev") || window.location.href.toLowerCase().includes("localhost")
          ? "-dev"
          : window.location.href.toLowerCase().includes("staging")
          ? "-staging"
          : ""
      }.wwdb.com${environment.WEB_APP_COUNTRY === "AU" ? ".au" : ""}/invite/${_code.inviteCode}`);
    } else {
      setAuthErr(true);
    }
  }, [_code]);

  const handleGetCode = async () => {
    setShowLoadingIcon(true);
    await fetchCode();
    setShowLoadingIcon(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.linkPage}>
        <div className={styles.linkPageHeader}>
          <div className={styles.backButton} onClick={() => navigate("/")}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.914 17.5L10.414 12L15.914 6.5L14.5 5.086L7.586 12L14.5 18.914L15.914 17.5Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.linkPageHeaderText}>Create an Invite Link</div>
        </div>
        {codeStatus === "loading" ? null : authErr ? (
          <div className={styles.linkPageSubheaderText}>
            You must be signed in in order to generate an invite link.
          </div>
        ) : codeStatus === "loading" ? (
          <div className={styles.linkPageSubheaderText}>Generating code...</div>
        ) : codeStatus === "error" ? (
          <div className={styles.linkPageSubheaderText}>{code.message}</div>
        ) : (
          <>
            <div className={styles.linkPageSubheaderText}>
              Send this invite to your new IBO
            </div>
            <div className={styles.inviteLink}>{url}</div>
            <div className={styles.buttonContainer}>
              <NextButton
                className={styles.copyLinkBtn}
                title={"Copy Link"}
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  setShowToast(true);
                  setTimeout(() => {
                    setShowToast(false);
                  }, 3000);
                }}
              />
            </div>
            <div className={styles.disclaimer}>
              Please note: An invite link can only be used once. As soon as an
              IBO uses the link they were sent, it cannot be used by any other
              IBO.
            </div>
          </>
        )}
        {!window?.navigator?.userAgent.includes("Android") && (
          <NextButton
            className={styles.copyToast}
            style={{ opacity: showToast ? 1 : 0 }}
            title="Copied!"
          />
        )}
      </div>
    </div>
  );
}
