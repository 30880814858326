import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { HeaderModal } from "components/HeaderModal";
import CoIbo from "components/CoIBO";
import EmergencyContact from "components/EmergencyContact";
import LoginInformation from "components/LoginInformation";
import PersonalInformation from "components/PersonalInformation";
import Address from "components/Address";
import ChooseYourMembership from "components/ChooseYourMembership";
import Payment from "components/Payment";
import TermsAndConditions from "components/TermsAndConditions";
import { useSessionStorage } from "../../hooks/UseSessionStorage/useSessionStorage";
import { SignUpContainer } from "hooks/useSignUp";
import AddressConfirmation from "pages/AddressConfirmation";
import { FooterModal } from "components/FooterModal";

import environment from "shared/environment";

/**
 * All the personal information components together in one page. Clicking next will handle errors and display a header modal if an error appears. If not error that step will be hidden and the next one is displayed.
 */

const SignUpStep = {
  PersonalInformation: 1,
  CoIBO: 2,
  EmergencyContact: 3,
  Address: 4,
  ConfirmAddress: 5,
  LoginInformation: 6,
  ChooseYourMembership: 7,
  Payment: 8,
  TermsAndConditions: 9,
};

export default function Information() {
  const {
    signUpValueRef,
    setSignUpValue,
    setShowLoadingIcon,
    error,
    setOneError,
    clearPageError,
    showFooter,
    setShowFooter,
    validateContactInfo,
    validateAddress,
    validateCredentials,
    getAvailableSubscriptions,
    makePurchase,
  } = SignUpContainer.useContainer();

  // DEBUG
  // useEffect(() => {
  //   console.log(signUpValueRef);
  // }, [signUpValueRef]);

  const iboNum = signUpValueRef.current.iboNum;
  let navigate = useNavigate();

  useEffect(() => {
    if (!iboNum) {
      navigate("/login");
    }
  }, [iboNum, navigate]);

  //Modal
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useSessionStorage("isOpen", false);

  //Navigation
  const [step, setStep] = useState(SignUpStep.PersonalInformation);

  //PersonalInformation
  const [firstName, setFirstName] = useSessionStorage("firstName", "");
  const [lastName, setLastName] = useSessionStorage("lastName", "");
  const [phoneNumber, setPhoneNumber] = useSessionStorage("phoneNumber", "");
  const [email, setEmail] = useSessionStorage("email", "");
  const [middleName, setMiddleName] = useSessionStorage("middleName", "");

  //CoIbo
  const [secondaryFirstName, setSecondaryFirstName] = useSessionStorage(
    "secondaryFirstName",
    ""
  );
  const [secondaryMiddleName, setSecondaryMiddleName] = useSessionStorage(
    "secondaryMiddleName",
    ""
  );
  const [secondaryLastName, setSecondaryLastName] = useSessionStorage(
    "secondaryLastName",
    ""
  );
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useSessionStorage(
    "secondaryPhoneNumber",
    ""
  );
  const [secondaryEmail, setSecondaryEmail] = useSessionStorage(
    "secondaryEmail",
    ""
  );
  const [selected, setSelected] = useSessionStorage("selected", false);

  //EmergencyContact
  const [emergencyFirstName, setEmergencyFirstName] = useSessionStorage(
    "emergencyFirstName",
    ""
  );
  const [emergencyLastName, setEmergencyLastName] = useSessionStorage(
    "emergencyLastName",
    ""
  );
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useSessionStorage(
    "emergencyPhoneNumber",
    ""
  );

  //Address
  const [country, setCountry] = useSessionStorage("country", "");
  const [street, setStreet] = useSessionStorage("street", "");
  const [apt, setApt] = useSessionStorage("apt", "");
  const [city, setCity] = useSessionStorage("city", "");
  const [zip, setZip] = useSessionStorage("zip", "");
  const [state, setState] = useSessionStorage("state", "");

  //AddressConfirmation
  const [originalAddress, setOriginalAddress] = useState({});
  const [suggestedAddress, setSuggestedAddress] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});

  //LoginInformation
  const [userName, setUsername] = useSessionStorage("userName", "");
  const [password, setPassword] = useSessionStorage("password", "");
  const [passwordConfirmation, setPasswordConfirmation] = useSessionStorage(
    "passwordConfirmation",
    ""
  );

  //ChooseYourMembership
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [noCard, setNoCard] = useSessionStorage("noCard", "");
  const [visible, setVisible] = useSessionStorage("visible", "");

  //Payment
  const [cardZipCode, setCardZipCode] = useState("");
  const [cardNickName, setCardNickName] = useState("");

  const regPhoneUS = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const regPhoneCN = /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  const regPhoneAU = /^\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

  const regPhone =
    environment.WEB_APP_COUNTRY === "AU"
      ? regPhoneAU
      : environment.WEB_APP_COUNTRY === "CN"
      ? regPhoneCN
      : regPhoneUS;

  const regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validatePassword = (password) => {
    if (!password || password.length < 8) {
      return "password's length >= 8.";
    } else if (password.search(/[a-zA-Z]/) == -1) {
      return "password must have at least 1 letter.";
    } else if (password.search(/\d/) == -1) {
      return "password must have at least 1 number.";
    } else if (!/[!@#$%^&*:;]/.test(password)) {
      return "password must have at least 1 symbol of [!@#$%^&*:;].";
    }

    return "";
  };

  const numberPattern = /\d+/g;

  const onSubscriptionSelected = (value) => {
    setSelectedSubscription(value);
    setSignUpValue({ ...signUpValueRef.current, selectedSubscription: value });
  };

  const stepPersonalInformation = async () => {
    let isError = false;
    if (!firstName) {
      setOneError("PersonalInformation", "firstName", errorMessages.firstName);
      isError = true;
    } else {
      setOneError("PersonalInformation", "firstName", "");
    }

    if (!lastName) {
      setOneError("PersonalInformation", "lastName", errorMessages.lastName);
      isError = true;
    } else {
      setOneError("PersonalInformation", "lastName", "");
    }

    if (!regPhone.test(phoneNumber)) {
      setOneError("PersonalInformation", "phoneNumber", errorMessages.phone);
      isError = true;
    } else {
      setOneError("PersonalInformation", "phoneNumber", "");
    }

    if (!regEmail.test(email)) {
      setOneError("PersonalInformation", "email", errorMessages.email);
      isError = true;
    } else {
      setOneError("PersonalInformation", "email", "");
    }

    if (isError) return;

    setShowLoadingIcon(true);

    try {
      setOneError("PersonalInformation", "api", "");

      const validateRsp = await validateContactInfo
        .mutateAsync({ firstName, middleName, lastName, phoneNumber, email })
        .catch((err) => {
          const msg =
            err.response.data && err.response.data.error
              ? err.response.data.error
              : "";

          if (msg === "Email already exists") {
            setOneError(
              "PersonalInformation",
              "email",
              errorMessages.emailExisted
            );
            return;
          } else {
            setOneError("PersonalInformation", "email", "");
          }

          if (msg) {
            setOneError("PersonalInformation", "api", msg);
            return;
          } else {
            setOneError("PersonalInformation", "api", "");
          }

          throw Object.assign(new Error("something went wrong"), { code: 500 });
        });

      if (!validateRsp) {
        //Already error handling in catch
        return;
      }

      const _phoneNumber = phoneNumber.match(numberPattern).join("");

      setSignUpValue({
        ...signUpValueRef.current,
        mainIBO: {
          firstName,
          middleName,
          lastName,
          phoneNumber: _phoneNumber,
          email,
        },
      });
      setStep(SignUpStep.CoIBO);
    } catch (error) {
      setMessage("An error has occured. Please contact support!");
      modalToggled();
    } finally {
      setShowLoadingIcon(false);
    }
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const onNo = () => {
    if (noCard) {
      setStep(6);
    } else {
      setStep(7);
    }
  };

  const onBackToWelcome = () => {
    navigate(`/welcome`);
  };

  const stepCoIBO = async () => {
    if (selected) {
      setSignUpValue({ ...signUpValueRef.current, coIBO: null });
      setStep(SignUpStep.EmergencyContact);
      return;
    }

    let isError = false;

    if (
      !secondaryFirstName &&
      !secondaryLastName &&
      !secondaryPhoneNumber &&
      !secondaryEmail
    ) {
      isError = true;
      setOneError("CoIbo", "secondary", errorMessages.secondary);
      return;
    } else {
      setOneError("CoIbo", "secondary", "");
    }

    if (!secondaryFirstName) {
      isError = true;
      setOneError(
        "CoIbo",
        "secondaryFirstName",
        errorMessages.secondaryFirstName
      );
    } else {
      setOneError("CoIbo", "secondaryFirstName", "");
    }

    if (!secondaryLastName) {
      isError = true;
      setOneError(
        "CoIbo",
        "secondaryLastName",
        errorMessages.secondaryLastName
      );
    } else {
      setOneError("CoIbo", "secondaryLastName", "");
    }

    if (!regPhone.test(secondaryPhoneNumber)) {
      isError = true;
      setOneError("CoIbo", "secondaryPhoneNumber", errorMessages.phone);
    } else {
      setOneError("CoIbo", "secondaryPhoneNumber", "");
    }

    if (!regEmail.test(secondaryEmail)) {
      isError = true;
      setOneError("CoIbo", "secondaryEmail", errorMessages.email);
    } else if (secondaryEmail.toLowerCase() === email.toLowerCase()) {
      isError = true;
      setOneError("CoIbo", "secondaryEmail", errorMessages.secondaryEmailSame);
    } else {
      setOneError("CoIbo", "secondaryEmail", "");
    }

    if (isError) return;

    setShowLoadingIcon(true);

    try {
      setOneError("CoIbo", "api", "");

      const validateRsp = await validateContactInfo
        .mutateAsync({
          firstName: secondaryFirstName,
          middleName: secondaryMiddleName,
          lastName: secondaryLastName,
          phoneNumber: secondaryPhoneNumber,
          email: secondaryEmail,
        })
        .catch((err) => {
          const msg =
            err.response.data && err.response.data.error
              ? err.response.data.error
              : "";

          if (msg === "Email already exists") {
            setOneError("CoIbo", "secondaryEmail", errorMessages.emailExisted);
            return;
          } else {
            setOneError("CoIbo", "secondaryEmail", "");
          }

          if (msg) {
            setOneError("CoIbo", "api", msg);
            return;
          } else {
            setOneError("CoIbo", "api", "");
          }

          throw Object.assign(new Error("something went wrong"), { code: 500 });
        });

      if (!validateRsp) {
        //Already error handling in catch
        return;
      }

      const _phoneNumber = secondaryPhoneNumber.match(numberPattern).join("");

      setSignUpValue({
        ...signUpValueRef.current,
        coIBO: {
          firstName: secondaryFirstName,
          middleName: secondaryMiddleName,
          lastName: secondaryLastName,
          phoneNumber: _phoneNumber,
          email: secondaryEmail,
        },
      });
      setStep(SignUpStep.EmergencyContact);
    } catch (error) {
      setMessage("An error has occured. Please contact support!");
      modalToggled();
    } finally {
      setShowLoadingIcon(false);
    }
  };

  const stepEmergencyContact = () => {
    let isError = false;
    if (emergencyPhoneNumber && !regPhone.test(emergencyPhoneNumber)) {
      isError = true;
      setOneError(
        "EmergencyContact",
        "emergencyPhoneNumber",
        errorMessages.phone
      );
    } else {
      setOneError("EmergencyContact", "emergencyPhoneNumber", "");
    }

    if (isError) return;

    setSignUpValue({
      ...signUpValueRef.current,
      emergency: {
        firstName: emergencyFirstName,
        lastName: emergencyLastName,
        phoneNumber: emergencyPhoneNumber,
      },
    });

    setStep(SignUpStep.Address);
  };

  const stepAddress = async () => {
    let isError = false;
    if (!country || country.length !== 2) {
      isError = true;
      setOneError("Address", "country", errorMessages.country);
    } else {
      setOneError("Address", "country", "");
    }

    if (!street) {
      isError = true;
      setOneError("Address", "street", errorMessages.street);
    } else {
      setOneError("Address", "street", "");
    }

    if (!city) {
      isError = true;
      setOneError("Address", "city", errorMessages.city);
    } else {
      setOneError("Address", "city", "");
    }

    // input controlled so removing state length restrictions
    // regions like AU can have 3 character states/province abbr.
    if (
      !country ||
      !state
      // || state.length !== 2
    ) {
      isError = true;
      setOneError("Address", "state", errorMessages.state);
    } else {
      setOneError("Address", "state", "");
    }
    if (!zip) {
      isError = true;
      setOneError("Address", "zip", errorMessages.zip);
    } else {
      setOneError("Address", "zip", "");
    }

    if (isError) return;

    setShowLoadingIcon(true);

    try {
      setOneError("Address", "api", "");

      let original = {
        country: country,
        state: state,
        city: city,
        postalCode: zip,
        line1: street,
        line2: apt,
      };
      setOriginalAddress(original);

      //validate the Address
      const validateRsp = await validateAddress
        .mutateAsync(original)
        .catch((err) => {
          const msg =
            err.response.data && err.response.data.error
              ? err.response.data.error
              : "";

          if (msg) {
            setOneError("Address", "api", msg);
            return;
          } else {
            setOneError("Address", "api", "");
          }

          throw Object.assign(new Error("something went wrong"), { code: 500 });
        });

      if (!validateRsp) return;

      setSuggestedAddress({ ...validateRsp, state: state });

      setStep(SignUpStep.ConfirmAddress);
    } catch (error) {
      setMessage("An error has occured. Please contact support!");
      modalToggled();
    } finally {
      setShowLoadingIcon(false);
    }
  };

  const stepLoginInformation = async () => {
    let isError = false;
    if (
      !userName ||
      userName.length < 3 ||
      userName.length > 50 ||
      userName.includes(":")
    ) {
      isError = true;
      setOneError("LoginInformation", "userName", errorMessages.username);
    } else {
      setOneError("LoginInformation", "userName", "");
    }

    let error = validatePassword(password);
    if (error) {
      isError = true;
      setOneError("LoginInformation", "password", errorMessages.password);
    } else {
      setOneError("LoginInformation", "password", "");
    }

    if (!passwordConfirmation || password !== passwordConfirmation) {
      isError = true;
      setOneError(
        "LoginInformation",
        "passwordConfirmation",
        errorMessages.passwordMatch
      );
    } else {
      setOneError("LoginInformation", "passwordConfirmation", "");
    }

    if (isError) return;

    setShowLoadingIcon(true);

    try {
      setOneError("LoginInformation", "api", "");

      const credRsp = await validateCredentials
        .mutateAsync({ username: userName, password: password })
        .catch((err) => {
          const msg =
            err.response.data && err.response.data.error
              ? err.response.data.error
              : "";

          if (msg === "error_username_not_valid") {
            setOneError(
              "LoginInformation",
              "userName",
              errorMessages.usernameApiInvalid
            );
            return;
          }

          if (msg === "error_password_not_valid") {
            setOneError("LoginInformation", "password", errorMessages.password);
            return;
          }

          if (msg === "error_username_existed") {
            setOneError(
              "LoginInformation",
              "userName",
              errorMessages.usernameExisted
            );
            return;
          }

          throw Object.assign(new Error("something went wrong"), { code: 500 });
        });

      if (!credRsp) return;

      if (
        !selectedAddress ||
        !signUpValueRef.current ||
        !signUpValueRef.current.iboNum
      ) {
        setOneError("LoginInformation", "api", "Data signup is invalid.");
        return;
      } else {
        setOneError("LoginInformation", "api", "");
      }

      setSignUpValue({
        ...signUpValueRef.current,
        username: userName,
        password: password,
      });

      const subscriptionRsp = await getAvailableSubscriptions
        .mutateAsync(selectedAddress)
        .catch((err) => {
          const msg =
            err.response.data && err.response.data.error
              ? err.response.data.error
              : "";

          if (msg) {
            setOneError("LoginInformation", "api", msg);
            return;
          } else {
            setOneError("LoginInformation", "api", "");
          }

          throw Object.assign(new Error("something went wrong"), { code: 500 });
        });
      if (!subscriptionRsp) {
        return;
      }

      setAvailableSubscriptions(subscriptionRsp);
      onSubscriptionSelected(subscriptionRsp[0]);
      setStep(SignUpStep.ChooseYourMembership);
    } catch (error) {
      setMessage("An error has occured. Please contact support!");
      modalToggled();
    } finally {
      setShowLoadingIcon(false);
    }
  };

  const onChooseYourMembershipNext = () => {
    setSignUpValue({
      ...signUpValueRef.current,
      selectedSubscription: selectedSubscription,
      noCard: noCard,
    });
    if (noCard) {
      setShowFooter(true);
    } else {
      setStep(SignUpStep.Payment);
    }
  };

  const stepPaymentSubmit = () => {
    setShowLoadingIcon(true);

    setOneError("Payment", "default", "");

    let isError = false;
    if (!cardZipCode) {
      isError = true;
      setOneError("Payment", "cardZipCode", errorMessages.cardZipCode);
    } else {
      setOneError("Payment", "cardZipCode", "");
    }

    if (!cardNickName) {
      isError = true;
      setOneError("Payment", "cardNickName", errorMessages.cardNickName);
    } else {
      setOneError("Payment", "cardNickName", "");
    }

    const cardError = (error && error.Payment) || {};
    if (cardError.js_ccnumber) {
      isError = true;
      setOneError("Payment", "ccnumber", cardError.js_ccnumber);
    } else {
      setOneError("Payment", "ccnumber", "");
    }

    if (cardError.js_ccexp) {
      isError = true;
      setOneError("Payment", "ccexp", cardError.js_ccexp);
    } else {
      setOneError("Payment", "ccexp", "");
    }

    if (isError) {
      setShowLoadingIcon(false);
      return;
    }

    setSignUpValue({ ...signUpValueRef.current, cardNickname: cardNickName });
    setSignUpValue({ ...signUpValueRef.current, cardZipCode: cardZipCode });

    window.CollectJS.startPaymentRequest(); //hint: check configureGlacier callback for next flow
    //should call setShowLoadingIcon(false); in configure glacier callback
  };

  const stepPayment = () => {
    setStep(8); //terms and conditions?
  };

  const modalToggled = () => {
    setIsOpen(!isOpen);
    //console.log("it worked");
  };

  const onSuggestedSelected = () => {
    setSignUpValue({
      ...signUpValueRef.current,
      selectedAddress: suggestedAddress,
      primaryVerified: 1,
    });
    setSelectedAddress(suggestedAddress);
    setStep(SignUpStep.LoginInformation);
  };

  const onOriginalSelected = () => {
    setSignUpValue({
      ...signUpValueRef.current,
      selectedAddress: originalAddress,
      primaryVerified: 0,
    });
    setSelectedAddress(originalAddress);
    setStep(SignUpStep.LoginInformation);
  };

  const onMakePurchase = async () => {
    setShowLoadingIcon(true);

    const checkpoint = await makePurchase();

    setShowLoadingIcon(false);

    if (checkpoint.name !== "allset") {
      console.log(`hint@${checkpoint.code}`, checkpoint.err);
      setMessage(
        `An error (code: ${checkpoint.code}) occurred. Please try again. If the problem continues, please contact Support.`
      );
      modalToggled();
      return;
    }

    //Clear cache
    //IBO-A
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    //IBO-B
    setSecondaryFirstName("");
    setSecondaryMiddleName("");
    setSecondaryLastName("");
    setSecondaryPhoneNumber("");
    setSecondaryEmail("");
    setSelected(false);
    //Emergency
    setEmergencyFirstName("");
    setEmergencyLastName("");
    setEmergencyPhoneNumber("");
    //Address
    setCountry("");
    setStreet("");
    setApt("");
    setCity("");
    setZip("");
    setState("");
    //user
    setUsername("");
    setPassword("");
    setPasswordConfirmation("");
    //Card
    setNoCard("");
    setVisible("");
    //Toggle
    setIsOpen(false);

    navigate(
      `/all-set/${signUpValueRef.current.receiptId}/${signUpValueRef.current.iboNum}`
    );
  };

  return iboNum ? (
    <>
      <HeaderModal
        visible={isOpen}
        info={message}
        btnTitle={"Okay"}
        onClick={modalToggled}
      />

      {step === SignUpStep.PersonalInformation && (
        <PersonalInformation
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          email={email}
          setFirstName={setFirstName}
          setMiddleName={setMiddleName}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
          setEmail={setEmail}
          onClick={stepPersonalInformation}
          onBack={onBackToWelcome}
          iboNum={iboNum}
        />
      )}

      {step === SignUpStep.CoIBO && (
        <CoIbo
          secondaryFirstName={secondaryFirstName}
          secondaryMiddleName={secondaryMiddleName}
          secondaryLastName={secondaryLastName}
          secondaryPhoneNumber={secondaryPhoneNumber}
          secondaryEmail={secondaryEmail}
          setSecondaryFirstName={setSecondaryFirstName}
          setSecondaryMiddleName={setSecondaryMiddleName}
          setSecondaryLastName={setSecondaryLastName}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          setSecondaryEmail={setSecondaryEmail}
          onClick={stepCoIBO}
          onBack={onBack}
          selected={selected}
          setSelected={(selected) => {
            if (selected) {
              clearPageError("CoIbo");
            }
            setSelected(selected);
          }}
          iboNum={iboNum}
        />
      )}

      {step === SignUpStep.EmergencyContact && (
        <EmergencyContact
          emergencyFirstName={emergencyFirstName}
          emergencyLastName={emergencyLastName}
          emergencyPhoneNumber={emergencyPhoneNumber}
          setEmergencyFirstName={setEmergencyFirstName}
          setEmergencyLastName={setEmergencyLastName}
          setEmergencyPhoneNumber={setEmergencyPhoneNumber}
          onClick={stepEmergencyContact}
          onBack={onBack}
          iboNum={iboNum}
        />
      )}

      {step === SignUpStep.Address && (
        <Address
          country={country}
          setCountry={(value) => {
            setState(null);
            setCountry(value);
          }}
          street={street}
          setStreet={setStreet}
          apt={apt}
          setApt={setApt}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          onClick={stepAddress}
          onBack={onBack}
          iboNum={iboNum}
        />
      )}

      {step === SignUpStep.ConfirmAddress && (
        <AddressConfirmation
          original={{
            country: country,
            state: state,
            city: city,
            postalCode: zip,
            line1: street,
            line2: apt,
          }}
          suggested={suggestedAddress}
          onCancel={() => setStep(SignUpStep.Address)}
          visible={true}
          onOriginalSelected={onOriginalSelected}
          onSuggestedSelected={onSuggestedSelected}
          onEdit={() => setStep(SignUpStep.Address)}
        />
      )}

      {step === SignUpStep.LoginInformation && (
        <LoginInformation
          userName={userName}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          passwordConfirmation={passwordConfirmation}
          setPasswordConfirmation={setPasswordConfirmation}
          onClick={stepLoginInformation}
          onBack={onBack}
          iboNum={iboNum}
        />
      )}

      {step === SignUpStep.ChooseYourMembership && (
        <ChooseYourMembership
          subscriptions={availableSubscriptions}
          visible={visible}
          setVisible={setVisible}
          iboNum={iboNum}
          onBack={onBack}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={onSubscriptionSelected}
          noCard={noCard}
          setNoCard={setNoCard}
          onNext={onChooseYourMembershipNext}
        />
      )}
      {step === SignUpStep.Payment && (
        <Payment
          zipCode={cardZipCode}
          cardZipCode={cardZipCode}
          setZipCode={setCardZipCode}
          nickname={cardNickName}
          setNickname={setCardNickName}
          visible={visible}
          setVisible={setVisible}
          iboNum={iboNum}
          onClick={stepPayment}
          onPaymentSubmit={stepPaymentSubmit}
          onBack={onBack}
        />
      )}

      {step === SignUpStep.TermsAndConditions && (
        <TermsAndConditions onBack={onNo} onYes={onMakePurchase} />
      )}

      <FooterModal
        visible={showFooter}
        onCancel={() => setShowFooter(false)}
        onClick={() => {
          setStep(SignUpStep.TermsAndConditions);
          setShowFooter(false);
        }}
        selectedSubscription={selectedSubscription}
      />
    </>
  ) : null;
}

Information.propTypes = {
  iboNum: PropTypes.string,
};

const errorMessages = {
  //PersonalInformation
  firstName: "Please enter first name.",
  lastName: "Please enter last name.",

  //PersonalInformation && CoIBO : for phone & email existed
  phone: "Please enter a valid 10 digit phone number.",
  emailExisted: "Email already exists.",

  //PersonalInformation && CoIBO & Emergency Contact: for email
  email: "Please enter a valid email address.",

  //CoIBO
  secondary: `Please enter your co-IBO information or select "I don't have a co-IBO".`,
  secondaryEmailSame: "Co-IBO's email cannot be the same as Main-IBO's email.",
  secondaryFirstName: "Please enter Co-IBO's first name.",
  secondaryLastName: "Please enter Co-IBO's last name.",

  //Address
  country: "Please select a country.",
  street: "Please enter a street.",
  city: "Please enter a city.",
  state: "Please select a state.",
  zip: "Please enter a zip.",

  //LoginInformation
  username: "Please enter a username.",
  usernameExisted: "Username already exists!",
  usernameApiInvalid: "Please enter a valid username.",
  password:
    "Password must have at least eight characters, one letter, one number, and one symbol.",
  passwordMatch: "Password must match confirmation.",
  apiCredError: "Validate credential error.",
  apiSubscriptionError: "Get available subscriptions error.",

  //Payment
  cardZipCode: "Please enter zip code",
  cardNickName: "Please enter Nickname",
};
