import PersonalInfoHeader from "components/PersonalInfoHeader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import { Ok, IkonateContext } from "react-ikonate";
import MembershipChoiceItem from "components/MembershipChoiceItem";
import PropTypes from "prop-types";

import Premier from "../../Images/Premier_Icon.svg";
import PremierDigital from "../../Images/Premier_Digital_Icon.svg";

/**
 * Membership choice step with info about each option. The user has the choice to continue with out without payment method at this step.
 */

export default function ChooseYourMembership({
  visible,
  setVisible,
  iboNum,
  onBack,
  selectedSubscription,
  setSelectedSubscription,
  noCard,
  setNoCard,
  subscriptions,
  onNext,
}) {
  const getSubsriptionImage = (subscription) => {
    const data = subscriptionConstants.find(
      (a) => a.type === subscription.type
    );
    return data && data.img ? data.img : null;
  };

  // console.log(subscriptions)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Choose Your Membership"}
          />
        </div>
        <div className={styles.choices}>
          {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map((a) => {
              // for now, only want pm + dd option shown for new trial memberships
              if (a.type === 12) {
                return (
                  <MembershipChoiceItem
                    key={a.title}
                    selected={selectedSubscription === a}
                    onClick={() => setSelectedSubscription(a)}
                    img={getSubsriptionImage(a)}
                    title={a.title}
                    price={`${a.amount}`}
                    amountDue={"0"}
                    amountDueAfter={`${a.proratedAmount}`}
                  />
                );
              }
            })}
        </div>
        <div className={styles.info}>
          A credit card is required to sign up for Premier Membership. Your
          credit card will not be charged until your complimentary period
          expires.
        </div>
        {/* <div className={styles.info}>
          Entering credit card information is optional during your first 30
          days.
        </div>
        <div className={styles.info}>
          *If you choose to add your credit card information now, your card will
          be automatically billed for the prorated amount at the end of your
          30-day trial{" "}
          <i>
            <b>(the “Due after free 30 days” amount above).</b>
          </i>
        </div>{" "}
        <div className={styles.info}>
          We bill all accounts on the second business day each month. If your
          free trial ends before the second business day of the next month, you
          will be billed a pro-rated amount for the remainder of your current
          month.
        </div> */}
        {/* <div className={styles.checkBox}>
          <div className={styles.containerBox}>
            <input
              type="checkbox"
              checked={noCard}
              className={styles.checkbox}
              onChange={() => setNoCard(!noCard)}
            />
            <div className={styles.icon}>
              <IkonateContext.Provider
                value={{ color: "#91b6bb", size: 15, border: 4 }}
              >
                <Ok />
              </IkonateContext.Provider>
            </div>
          </div>
          <div className={styles.checkBoxInfo}>
            I want to continue without adding a credit card
          </div>
        </div> */}
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <div>
              <NextButton title={"Next"} arrow={"right"} onClick={onNext} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ChooseYourMembership.propTypes = {
  visible: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // Used to display footer modal
  setVisible: PropTypes.func, // open footer modal
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBack: PropTypes.func, // function passed in for back button
  setStep: PropTypes.func, // function passed in to set next step
  membershipChoice: PropTypes.string, // displays selected membership choice
  setMembershipChoice: PropTypes.func, // sets selected membership choice
  noCard: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // if no card checkbox is checked
  setNoCard: PropTypes.func, // sets if no card checkbox is checked
};

const subscriptionConstants = [
  { type: 4, img: Premier },
  { type: 12, img: PremierDigital },
];
