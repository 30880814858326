import { DomainConf } from "@wwg/domain-conf";

/**
 * Define configurations here to be loaded based on the domain
 * the app is being served from.
 */
const config = new DomainConf()
  .defaults({
    localDevMode: false,
    WWG_WEB_URL: "https://www.wwdb.com",
    WWG_HELP_URL: "https://app-help-web.wwdb.com/help",
    COMMUNIKATE_WEB_URL: "https://signup.katewwdb.com/",
    WWG_IOS_URL: "https://apps.apple.com/us/app/wwg/id460091416",
    WWG_ANDROID_URL:
      "https://play.google.com/store/apps/details?id=com.wwdb&pli=1",
    DREAMSTREAM_IOS_URL:
      "https://apps.apple.com/us/app/dreamstream-by-wwg/id6444070428",
    DREAMSTREAM_ANDROID_URL:
      "https://play.google.com/store/apps/details?id=today.dreamstream.www",
    COMMUNIKATE_IOS_URL:
      "https://apps.apple.com/us/app/communikate-mobile/id695940611",
    COMMUNIKATE_ANDROID_URL:
      "https://play.google.com/store/apps/details?id=com.parusholdings.katemobile",
  })
  .forDomain("localhost", {
    apiBase: "https://app-membership-sign-up-api-dev.wwdb.com",
    // apiBase: "http://localhost:3750",

    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    // coreApiUrl: "https://app-core-web.wwdb.com.au",

    localDevMode: true, // Set to true to enable local login for developers.
    rootDomain: ".wwdb.com",

    WEB_APP_COUNTRY: "US",
    // WEB_APP_COUNTRY: "AU",

    DREAMSTREAM_WEB_URL: "https://dev.dreamstream.today",
    WWG_WEB_URL: "https://apps-dev.wwdb.com",
    WWG_HELP_URL: "https://app-help-web-dev.wwdb.com/help",
  })
  // for mobile testing
  .forDomain("10.0.0.151", {
    apiBase: "https://app-membership-sign-up-api-dev.wwdb.com",
    //apiBase: "http://localhost:3750",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    localDevMode: true, // Set to true to enable local login for developers.
    rootDomain: ".wwdb.com",
    WEB_APP_COUNTRY: "US",
    DREAMSTREAM_WEB_URL: "https://dev.dreamstream.today",
    WWG_WEB_URL: "https://apps-dev.wwdb.com",
    WWG_HELP_URL: "https://app-help-web-dev.wwdb.com/help",
  })
  .forDomain("signup-dev.wwdb.com", {
    apiBase: "https://app-membership-sign-up-api-dev.wwdb.com",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    rootDomain: ".wwdb.com",
    WEB_APP_COUNTRY: "US",
    DREAMSTREAM_WEB_URL: "https://dev.dreamstream.today",
    WWG_WEB_URL: "https://apps-dev.wwdb.com",
    WWG_HELP_URL: "https://app-help-web-dev.wwdb.com/help",
  })
  .forDomain("signup-staging.wwdb.com", {
    apiBase: "https://app-membership-sign-up-api-staging.wwdb.com",
    coreApiUrl: "https://app-core-web-staging.wwdb.com",
    rootDomain: ".wwdb.com",
    WEB_APP_COUNTRY: "US",
    DREAMSTREAM_WEB_URL: "https://staging.dreamstream.today",
    WWG_WEB_URL: "https://staging.wwdb.com",
    WWG_HELP_URL: "https://app-help-web-dev.wwdb.com/help",
  })
  .forDomain("signup.wwdb.com", {
    apiBase: "https://app-membership-sign-up-api.wwdb.com",
    coreApiUrl: "https://app-core-web.wwdb.com",
    membershipSignUpApiUrl: "https://app-membership-sign-up-api.wwdb.com",
    rootDomain: ".wwdb.com",
    WEB_APP_COUNTRY: "US",
    DREAMSTREAM_WEB_URL: "https://www.dreamstream.today",
    WWG_WEB_URL: "https://www.wwdb.com",
    WWG_HELP_URL: "https://app-help-web.wwdb.com/help",
  })
  // Australia & New Zealand
  .forDomain("signup-staging.wwdb.com.au", {
    apiBase: "https://app-membership-sign-up-api-staging.wwdb.com.au",
    coreApiUrl: "https://app-core-web-staging.wwdb.com.au",
    rootDomain: ".wwdb.com.au",
    WEB_APP_COUNTRY: "AU",
    DREAMSTREAM_WEB_URL: "https://staging.dreamstream.today",
    WWG_WEB_URL: "https://staging.wwdb.com.au",
    WWG_HELP_URL: "https://app-help-web.wwdb.com/help",
    WWG_IOS_URL: "https://apps.apple.com/au/app/wwg-anz/id1488547356",
    WWG_ANDROID_URL:
      "https://play.google.com/store/apps/details?id=au.com.wwdb&gl=AU&pli=1",
    COMMUNIKATE_WEB_URL: "https://signup-au.katewwdb.com/",
  })
  .forDomain("signup.wwdb.com.au", {
    apiBase: "https://app-membership-sign-up-api.wwdb.com.au",
    coreApiUrl: "https://app-core-web.wwdb.com.au",
    rootDomain: ".wwdb.com.au",
    WEB_APP_COUNTRY: "AU",
    DREAMSTREAM_WEB_URL: "https://www.dreamstream.today",
    WWG_WEB_URL: "https://www.wwdb.com.au",
    WWG_HELP_URL: "https://app-help-web.wwdb.com.au/help",
    WWG_IOS_URL: "https://apps.apple.com/au/app/wwg-anz/id1488547356",
    WWG_ANDROID_URL:
      "https://play.google.com/store/apps/details?id=au.com.wwdb&gl=AU&pli=1",
    COMMUNIKATE_WEB_URL: "https://signup-au.katewwdb.com/",
  });

// Export the selected configuration from this file.
// You can import this wherever you need access to these values.
export default config.current;
