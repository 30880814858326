import classnames from "classnames";
import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import PersonalInfoSubheader from "components/PersonalInfoSubheader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import PropTypes from "prop-types";
import { SignUpContainer } from "hooks/useSignUp";

/**
 * Step for user to add main ibo information
 */

export default function PersonalInformation({
  firstName,
  lastName,
  phoneNumber,
  email,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  onClick,
  middleName,
  setMiddleName,
  onBack,
  iboNum,
}) {
  const { error } = SignUpContainer.useContainer();

  const pageError = (error && error.PersonalInformation) || {};

  const topError =
    pageError.firstName ||
    pageError.lastName ||
    pageError.phoneNumber ||
    pageError.email ||
    pageError.api;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Personal Information"}
          />
        </div>
        <div className={styles.header}>
          <PersonalInfoSubheader
            header={"Main IBO"}
            info={[
              "This is information for the main IBO on your Amway™ account",
            ]}
          />
        </div>
        {topError && <div className={styles.errorText}>{topError}</div>}
        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.firstName,
          })}
        >
          <InputBox
            placeHolder={"First Name*"}
            setValue={setFirstName}
            maxlength="20"
            required
            value={firstName}
          />
        </div>

        <div className={styles.input}>
          <InputBox
            placeHolder={"Middle Name"}
            setValue={setMiddleName}
            maxlength="20"
            value={middleName}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.lastName,
          })}
        >
          {" "}
          <InputBox
            placeHolder={"Last Name*"}
            setValue={setLastName}
            maxlength="20"
            required
            value={lastName}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.phoneNumber,
          })}
        >
          <InputBox
            placeHolder={"Phone Number*"}
            setValue={setPhoneNumber}
            required
            value={phoneNumber}
            maxlength={10}
            type={"tel"}
            pattern={/^[0-9\b]+$/}
          />
        </div>

        <div
          className={classnames(styles.input, {
            [styles.error]: !!pageError.email,
          })}
        >
          <InputBox
            placeHolder={"Email*"}
            setValue={setEmail}
            required
            value={email}
            maxlength={250}
          />
        </div>

        <div className={styles.required}>*Required</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <NextButton title={"Next"} arrow={"right"} onClick={onClick} />
          </div>
        </div>
      </div>
    </>
  );
}

PersonalInformation.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setPhoneNumber: PropTypes.func,
  setEmail: PropTypes.func,
  onClick: PropTypes.func,
  middleName: PropTypes.string,
  setMiddleName: PropTypes.func,
  onBack: PropTypes.func,
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
