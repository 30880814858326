import styles from "./index.module.css";
import PropTypes from "prop-types";

/**
 * Header displayed on welcome page and all set page
 */

export default function WelcomeHeader({ img }) {
  return (
    <>
      <div className={styles.container}>
        <img src={img} alt="Header Image" />
      </div>
      <div className={styles.container}>
        We’re thrilled to roll out our red carpet and offer you complimentary
        Premier Membership with World Wide Group™! Get ready to unlock exclusive
        perks, create powerful connections, and start on your own journey as you
        elevate your business to the next level.
      </div>
      <div className={styles.container}>
        It’s time to Build Your Life.™ Welcome to the beginning!
      </div>
    </>
  );
}

WelcomeHeader.propTypes = {
  img: PropTypes.string,
  info: PropTypes.string,
};
