import styles from "./index.module.css";
import PropTypes from "prop-types";

/**
 * Header used on Create your account pages in the app.
 */

export default function PersonalInfoHeader({ iboNumber, subHeader }) {
  return (
    <>
      <div className={styles.flex}>
        <div className={styles.createAccount}>Create Your Account</div>
        <div className={styles.ibo}>IBO Number:</div>
      </div>
      <div className={styles.flex}>
        <div className={styles.membershipChoice}>{subHeader}</div>
        <div className={styles.iboNumber}>{iboNumber}</div>
      </div>
    </>
  );
}

PersonalInfoHeader.propTypes = {
  iboNumber: PropTypes.number, 
  subHeader: PropTypes.string,
};
