import PropTypes from "prop-types";
import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";

/**
 * Custom button with rounded border displayed throughout the app.
 */

export function Button({
  disabled = false,
  title,
  onClick,
  className,
}) {
  return (
    <button
      disabled={disabled}
      className={classNames([
        styles.button,
        className,
        disabled && styles.disabled,
      ])}
      onClick={disabled ? null : onClick}
    >
      {title || "Button"}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
};
