import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

import { App } from "./App";
import { SignUpContainer } from "hooks/useSignUp";
import LoadingIcon from "components/LoadingIcon";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // keep data cached for 10 minutes unless invalidated earlier
    },
  },
});

function prepare() {
  // if (process.env.NODE_ENV === "development") {
  //   // const { worker } = require("./mocks/browser");
  //   // seeds(db);

  //   return worker.start();
  // }

  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SignUpContainer.Provider>
            <App />
            <LoadingIcon />
          </SignUpContainer.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
