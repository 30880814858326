import { Button } from "components/Button";
import styles from "./index.module.css";
import classNames from "classnames";

export default function AddressCard({
  header,
  onClick,
  className,
  arrow,
  backgroundColor,
  edit,
  address1,
  address2,
  address3,
  onEdit
}) {
  return (
    <div className={classNames(styles.card, backgroundColor)}>
      <div className={styles.header}>{header}</div>
      <div className={styles.container}>
        <div>{address1}</div>
        <div>{address2}</div>
        <div>{address3}</div>
      </div>
      <div className={styles.btnGroup}>
        <Button
          onClick={onClick}
          title={"Use " + header}
          className={className}
        />{" "}
        {arrow
          ? <img className={styles.arrow} src={arrow} alt="Header" />
          : ""
        }
        {edit ? (
          <Button
            onClick={onEdit}
            title={"Edit"}
            className={styles.edit}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
