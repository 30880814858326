import { SignUpContainer } from "hooks/useSignUp";
import React from "react";

import Lottie from "react-lottie-player";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

import lottieJson from "shared/Loading_Icon.json";

import styles from "./index.module.css";

export default function LoadingIcon() {
  const { showLoadingIcon } = SignUpContainer.useContainer();
  return (
    <>
      {showLoadingIcon ? (
        <>
          <div className={styles.backgroundOverlay}></div>
          <div className={styles.loadingIconContainer}>
            <div className={styles.ldsRing}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            {/* waiting new design that doesn't break on iOS */}
            {/* <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: "150px", height: "150px" }}
            /> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
