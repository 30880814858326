import { Button } from "components/Button";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { useState, useEffect, useRef, useCallback } from "react";
import mobile from "mobileBridge";

import environment from "shared/environment";
import ANZTerms from "./ANZTerms";
import USTerms from "./USTerms";

/**
 * Terms and conditions that needs to be accepted before they can finish payment step.
 */

// const useOnScreen = (rootMargin = "0px") => {
//   const observer = useRef();
//   const [isIntersecting, setIntersecting] = useState(false);

//   const observe = useCallback(
//     (element) => {
//       // init observer if one doesn't exist
//       if (!observer.current)
//         observer.current = new IntersectionObserver(
//           ([entry]) => {
//             setIntersecting(entry.isIntersecting);
//           },
//           { rootMargin }
//         );

//       // observe an element
//       if (element) observer.current.observe(element);
//     },
//     [rootMargin]
//   );

//   return [isIntersecting, observe];
// };

export default function TermsAndConditions({ onBack, onYes }) {
  // const [read, setRead] = useState(false);

  // const [isVisible, observe] = useOnScreen();

  // useEffect(() => {
  //   isVisible && setRead(true);
  // }, [isVisible]);

  const country = environment.WEB_APP_COUNTRY.toLowerCase();

  return (
    <div className={styles.outerContainer}>
      {/*  */}
      <div className={styles.topContainer}>
        {country === "au" ? <ANZTerms /> : <USTerms />}
      </div>
      <div className={styles.container}>
        <div className={styles.btnGroup}>
          <Button
            onClick={onBack}
            title={"No"}
            className={styles.btnColor2}
          ></Button>

          <Button
            onClick={onYes}
            title={"Yes"}
            className={styles.btnColor}
          ></Button>
        </div>
      </div>
    </div>
  );
}

TermsAndConditions.propTypes = {
  onBack: PropTypes.func, // function used if user clicks no.
};
