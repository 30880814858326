import classnames from "classnames";
import { SignUpContainer } from "hooks/useSignUp";
import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import PersonalInfoSubheader from "components/PersonalInfoSubheader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import PropTypes from "prop-types";

/**
 * Step for user to add an emergency contact. Emergency contact is not required
 */

export default function EmergencyContact({
  emergencyFirstName,
  emergencyLastName,
  emergencyPhoneNumber,
  setEmergencyFirstName,
  setEmergencyLastName,
  setEmergencyPhoneNumber,
  onBack,
  onClick,
  iboNum
}) {
  const { error } = SignUpContainer.useContainer();

  const pageError = (error && error.EmergencyContact) || {};
  const topError = pageError.emergencyPhoneNumber;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Personal Information"}
          />
        </div>
        <div className={styles.header}>
          <PersonalInfoSubheader
            header={"Emergency Contact"}
            info={[
              "To purchase tickets to WWG™ events, you will need to have an emergency contact on file.",
              "Your emergency contact should be someone other than the Main IBO or Co-IBO listed on this account. You can change this information at any time from the Settings page.",
            ]}
          />
        </div>

        <div className={styles.required}>
          <i>This information is not required to sign up.</i>
        </div>

        {topError && <div className={styles.errorText}>{topError}</div>}
        <div className={styles.input}>
          <InputBox
            placeHolder={"First Name"}
            setValue={setEmergencyFirstName}
            value={emergencyFirstName}
            maxlength="20"
          />
        </div>

        <div className={styles.input}>
          {" "}
          <InputBox
            placeHolder={"Last Name"}
            setValue={setEmergencyLastName}
            value={emergencyLastName}
            maxlength="20"
          />
        </div>

        <div className={classnames(styles.input, {[styles.error]: !!pageError.emergencyPhoneNumber })}>
          <InputBox
            placeHolder={"Phone Number"}
            setValue={setEmergencyPhoneNumber}
            value={emergencyPhoneNumber}
            type="tel"
            maxlength="10"
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton arrow={"left"} className={styles.backgroundTwo} onClick={onBack} />
          </div>
          <div className={styles.next}>

              <NextButton title={"Next"} arrow={"right"} onClick={onClick}/>

          </div>
        </div>
      </div>
    </>
  );
}

EmergencyContact.propTypes = {
  emergencyFirstName: PropTypes.string,
  emergencyLastName: PropTypes.string,
  emergencyPhoneNumber: PropTypes.string,
  setEmergencyFirstName: PropTypes.func,
  setEmergencyLastName: PropTypes.func,
  setEmergencyPhoneNumber: PropTypes.func,
  onBack: PropTypes.func,
  onClick: PropTypes.func,
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
