import { useState } from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Custom Inputbox box. You can pass in a lable or Icon to use, but they are not required. setValue is a function passed in to set the value for the select box.
 */

/**
 * @example
 * No lable or Icon
 * <InputBox placeHolder={"Billing Zip Code"}/>
 * @example
 * With lable
 * <InputBox placeHolder={"Billing Zip Code"} label={"Zip Code:"} />
 * @example
 * With Icon
 * <InputBox placeHolder={"Billing Zip Code"} icon={<FontAwesomeIcon icon={faCcVisa} className={styles.iconClass} />} />
 */

export default function InputBox({
  label,
  placeHolder,
  icon,
  setValue,
  type = "text",
  value,
  title,
  id,
  name,
  maxlength,
  pattern = "",
}) {
  const [currentValue, setCurrentValue] = useState(value);

  const onChange = (e) => {
    setCurrentValue(e);
    if (setValue) {
      setValue(e);
    }
  };

  return (
    <div className={styles.input}>
      {label || icon ? (
        <label
          htmlFor={title}
          className={label ? styles.inputLabel : styles.inputLabel2}
        >
          {label ? label : icon}
        </label>
      ) : (
        ""
      )}
      <input
        placeholder={placeHolder}
        type={type}
        id={id}
        name={name}
        value={currentValue}
        onChange={(e) => {
          if (pattern) {
            if (e.target.value === "" || pattern.test(e.target.value)) {
              onChange(e.target.value);
            }
          } else {
            onChange(e.target.value);
          }
        }}
        className={classNames(
          styles.inputBox,
          label || icon ? "" : styles.noLabel
        )}
        maxLength={maxlength}
        pattern={pattern}
      />
    </div>
  );
}

InputBox.propTypes = {
  label: PropTypes.string, // Label for the input box
  placeHolder: PropTypes.string, // Placeholder for the input box
  setValue: PropTypes.func, // Sets the value of the input box
  icon: PropTypes.element, // Icon to be displayed inside label
  type: PropTypes.string, // Type of input
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};
