import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import { Button } from "components/Button";
import Modal from "react-modal";
import PropTypes from "prop-types";

/**
 * Header modal with overlay
 */

const customStyles = {
  content: {
    top: "-2%",
    bottom: "auto",
  },
  overlay: { zIndex: 1000, borderRadius: "6pt" },
};

export function HeaderModal({ visible, info, btnTitle, onClick }) {
  return (
    <>
      <div
        className={classNames(styles.modalOverlay, {
          [styles.show]: visible,
        })}
      >
        <Modal
          isOpen={visible}
          onRequestClose={onClick}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className={styles.container}>
            <div className={styles.info}>{info}</div>
            <div className={styles.btn}>
              <Button
                onClick={onClick}
                title={btnTitle}
                className={styles.btnColor}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

HeaderModal.propTypes = {
  visible: PropTypes.bool,
  info: PropTypes.string,
  btnTitle: PropTypes.string,
  onClick: PropTypes.func,
};
