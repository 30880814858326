import classnames from "classnames";
import { SignUpContainer } from "hooks/useSignUp";
import InputBox from "components/InputBox";
import PersonalInfoHeader from "components/PersonalInfoHeader";
import PersonalInfoSubheader from "components/PersonalInfoSubheader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import { Ok, IkonateContext } from "react-ikonate";
import PropTypes from "prop-types";

/**
 * Step for user to add coibo information
 */

export default function CoIbo({
  secondaryFirstName,
  secondaryMiddleName,
  secondaryLastName,
  secondaryPhoneNumber,
  secondaryEmail,
  setSecondaryFirstName,
  setSecondaryMiddleName,
  setSecondaryLastName,
  setSecondaryPhoneNumber,
  setSecondaryEmail,
  onBack,
  onClick,
  selected,
  setSelected,
  iboNum
}) {

  const { error } = SignUpContainer.useContainer();

  const pageError = (error && error.CoIbo) || {};
  const topError =
    pageError.secondary
    || pageError.secondaryFirstName
    || pageError.secondaryLastName
    || pageError.secondaryPhoneNumber
    || pageError.secondaryEmail
    || pageError.api
    ;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Personal Information"}
          />
        </div>
        <div className={styles.header}>
          <PersonalInfoSubheader
            header={"Co IBO"}
            info={["This is information for the Co IBO on your Amway™ account"]}
          />
        </div>

        {topError && <div className={styles.errorText}>{topError}</div>}
        <div className={classnames(styles.input, { [styles.error]: !!pageError.secondary || !!pageError.secondaryFirstName })}>
          <InputBox
            placeHolder={"First Name*"}
            maxlength="20"
            setValue={setSecondaryFirstName}
            value={secondaryFirstName}
          />
        </div>

        <div className={styles.input}>
          {" "}
          <InputBox
            placeHolder={"Middle Name"}
            setValue={setSecondaryMiddleName}
            value={secondaryMiddleName}
            maxlength="20"
          />
        </div>

        <div className={classnames(styles.input, { [styles.error]: !!pageError.secondary || !!pageError.secondaryLastName })}>
          {" "}
          <InputBox
            placeHolder={"Last Name*"}
            setValue={setSecondaryLastName}
            value={secondaryLastName}
            maxlength="20"
          />
        </div>

        <div className={classnames(styles.input, { [styles.error]: !!pageError.secondary || !!pageError.secondaryPhoneNumber })}>
          <InputBox
            placeHolder={"Phone Number*"}
            setValue={setSecondaryPhoneNumber}
            value={secondaryPhoneNumber}
            maxlength={10}
            type={"tel"}
            pattern={/^[0-9\b]+$/}
          />
        </div>

        <div className={classnames(styles.input, { [styles.error]: !!pageError.secondary || !!pageError.secondaryEmail })}>
          <InputBox
            placeHolder={"Email*"}
            setValue={setSecondaryEmail}
            value={secondaryEmail}
            maxlength={250}
          />
        </div>

        <div className={styles.required}>
          <div className={styles.checkBox}>
            <div className={styles.containerBox}>
              <input
                type="checkbox"
                checked={selected}
                className={styles.checkbox}
                onChange={() => setSelected(!selected)}
              />
              <div className={styles.icon}>
                <IkonateContext.Provider
                  value={{ color: "#fff", size: 15, border: 4 }}
                >
                  <Ok />
                </IkonateContext.Provider>
              </div>
            </div>
            <div className={styles.checkBoxInfo}>I don’t have a co-IBO</div>
          </div>
          <div>*Required</div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <NextButton title={"Next"} arrow={"right"} onClick={onClick} />
          </div>
        </div>
      </div>
    </>
  );
}

CoIbo.propTypes = {
  secondaryFirstName: PropTypes.string,
  secondaryMiddleName: PropTypes.string,
  secondaryLastName: PropTypes.string,
  secondaryPhoneNumber: PropTypes.string,
  secondaryEmail: PropTypes.string,
  setSecondaryFirstName: PropTypes.func,
  setSecondaryMiddleName: PropTypes.func,
  setSecondaryLastName: PropTypes.func,
  setSecondaryPhoneNumber: PropTypes.func,
  setSecondaryEmail: PropTypes.func,
  onBack: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
};
